import { UploadImageType, useChatFormSendMutation } from 'generated/graphql';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { gql } from 'urql';
import { useAttachImages } from 'hooks';
import SendIcon from './icons/Send';
import ClipIcon from './icons/Clip';
import { AttachedImages } from './ChatAttachedImages';

type Props = {
  setError: (error: Error) => void;
};

export const ChatForm = ({ setError }: Props) => {
  const inputFiles = React.useRef<HTMLInputElement>(null);

  const [mutationState, sendMutate] = useChatFormSendMutation();
  const { control, handleSubmit, reset } = useForm<{ message: string; files: string }>({
    defaultValues: { message: '' },
  });

  const { images, setAttachedImages, dispatch } = useAttachImages(inputFiles);

  const send = (e: React.FormEvent) => {
    e.preventDefault();
    handleSubmit(async ({ message }) => {
      if (!message) return;

      try {
        reset();
        dispatch({ type: 'reset' });
        await sendMutate({
          input: {
            body: message,
            imageIds: (images.length > 0 && images.map((i) => i.id)) || [],
          },
        });
      } catch (err) {
        setError(err);
      }
    })();
  };

  const showFileSelector = () => dispatch({ type: 'showSelector' });
  const removeImage = (id: number) => dispatch({ type: 'remove', id });

  return (
    <div className=" mt-auto flex-col" style={{ borderTop: '1px solid #eee' }}>
      {images.length ? <AttachedImages onRemove={removeImage} images={images} /> : null}
      <form onSubmit={send} className="flex relative">
        {mutationState.fetching ? (
          <>
            <div className="skeleton-loader-overlay" />
            <span className="text-gray-50 h-14 py-4 px-7 text-sm resize-none flex-shrink-0 flex-grow">
              Отправляем...
            </span>
          </>
        ) : (
          <>
            <Controller
              control={control}
              name="message"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <>
                  <input
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder="Задайте вопрос"
                    className="placeholder-gray-50 h-14 py-4 px-7 text-sm resize-none flex-shrink-0 flex-grow"
                  />
                </>
              )}
            />
          </>
        )}
        <Controller
          control={control}
          name="files"
          render={({ field: { onChange } }) => (
            <button
              type="button"
              onClick={showFileSelector}
              className="opacity-40 hover:opacity-80 flex items-center justify-center flex-shrink-0 w-8 mr-1 bg-white transition-opacity duration-200 lg:hover:opacity-40"
            >
              <input
                ref={inputFiles}
                onChange={(e) => {
                  onChange(e.target.value);

                  if (e.target.files) {
                    setAttachedImages(e.target.files, UploadImageType.ForChatAttachment);
                  }
                }}
                type="file"
                multiple
                accept=".jpg, .jpeg, .png"
                hidden
              />
              <ClipIcon size={20} />
            </button>
          )}
        />
        <>
          <button
            type="submit"
            className="flex items-center justify-center flex-shrink-0 w-10 bg-white opacity-80 hover:opacity-100 transition-opacity lg:hover:opacity-80"
          >
            <SendIcon size={18} />
          </button>
        </>
      </form>
    </div>
  );
};

gql`
  fragment ChatForm_message on ChatMessage {
    id
    createdAt
    type
    body
  }

  mutation ChatFormSendMutation($input: SendChatMessageInput!) {
    result: sendChatMessage(input: $input) {
      ... on ChatMessage {
        ...ChatForm_message
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
