import classNames from 'classnames';
import * as React from 'react';

export const Heart = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('fill-current', className)}
  >
    <path d="m13.47 4.8.496.496-.495-.495zm7.48 7.49-.494-.495a.75.75 0 0 0-.02.02l.515.474zM12 22l-.515.474a.7.7 0 0 0 1.03 0L12 22zM10.53 4.8l-.496.496.495-.495zm-7.48 0 .494.496L3.05 4.8zm0 7.49.514-.475a.655.655 0 0 0-.02-.02l-.495.494zM12 6.272l-.495.495a.7.7 0 0 0 .99 0L12 6.273zm1.966-.977a4.585 4.585 0 0 1 6.49 0l.99-.99a5.985 5.985 0 0 0-8.47 0l.99.99zm6.49 0a4.6 4.6 0 0 1 0 6.498l.99.99a6 6 0 0 0 0-8.478l-.99.99zm-.02 6.519-8.95 9.71 1.029.95 8.95-9.711-1.029-.95zM2.554 4.306a6 6 0 0 0 0 8.478l.99-.99a4.6 4.6 0 0 1 0-6.498l-.99-.99zm-.02 8.458 8.951 9.71 1.03-.948-8.951-9.711-1.03.949zm10.442-8.458L11.505 5.78l.99.99 1.471-1.473-.99-.99zm-1.952 0a5.985 5.985 0 0 0-8.47 0l.99.99a4.585 4.585 0 0 1 6.49 0l.99-.99zm1.471 1.473-1.47-1.473-.991.99 1.47 1.472.991-.99z" />
  </svg>
);
