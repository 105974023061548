import * as React from 'react';

type Props = {
  readonly onChange: (v: boolean) => void;
  readonly value: boolean;
};

export const Switch = ({ onChange, value }: Props) => (
  <button
    type="button"
    onClick={() => onChange(!value)}
    className={`w-14 h-5 rounded-2xl relative duration-400 ${value ? 'bg-orange-300' : 'bg-red-200'}`}
  >
    <span
      style={{
        boxShadow:
          '0 3px 3px 0 rgb(0 0 0 / 5%), 0 2px 2px 0 rgb(0 0 0 / 10%), 0 3px 1px 0 rgb(0 0 0 / 5%), 0 0 3px 1px rgb(0 0 0 / 5%)',
      }}
      className={`transition duration-400 ease-in-out w-7 h-7 left-0 rounded-full bg-white absolute -top-1 transform ${
        value ? 'translate-x-7 bg-orange-50' : 'translate-x-0'
      }`}
    />
  </button>
);
