import * as React from 'react';
import { navigate } from 'gatsby';
import { useUserResolver } from './Auth';

const rootClass =
  'flex items-center h-9 bg-orange-600 rounded-3xl uppercase tracking-wider px-7 transition-opacity duration-300 ease-in-out text-orange-50 text-xs font-bold hover:opacity-70';

type Props = {
  classNames?: string;
};

export const SignInButton: React.FC<Props> = ({ classNames, children }) => {
  const [getUser, signInModal, currentUser] = useUserResolver();

  if (currentUser) {
    return null;
  }

  const process = async () => {
    const user = await getUser();
    if (user) {
      navigate('/my/orders');
    }
  };

  return (
    <>
      {signInModal}
      <button type="button" onClick={process} className={children ? classNames : `${rootClass} ${classNames}`}>
        {children || 'Войти'}
      </button>
    </>
  );
};
