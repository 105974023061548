import * as React from 'react';
import { Link } from 'gatsby';
import { gql } from '@urql/core';
import { useBasicAuthEnterPhoneMutation } from 'generated/graphql';
import { normalizePhone } from 'utils';
import { ProgressButton } from './ProgressButton';
import { Countdown } from './Countdown';
import { useReCaptcha } from './ReCaptcha';

type Props = {
  readonly onSuccess: (phone: string, codeLength?: number) => void;
  readonly signInByPassword: () => void;
};

const sbClassName =
  'btn-orange h-14 px-20 mt-4 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide';

export const AuthEnterPhone = ({ onSuccess, signInByPassword }: Props) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [value, setValue] = React.useState(process.env.NODE_ENV === 'production' ? '' : '9999999999');
  const [mutationState, mutate] = useBasicAuthEnterPhoneMutation();
  const { isReady, executeReCaptcha } = useReCaptcha();

  const normalizedValue = normalizePhone(value);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(null);

    try {
      const captcha = await executeReCaptcha();

      const recaptchaResponse = typeof captcha === 'string' ? captcha : undefined;
      const res = await mutate({ phone: normalizedValue, recaptchaResponse });
      if (res.__typename === 'ErrorPayload') {
        throw new Error(res.message);
      }
      if (res.__typename === 'RequestSignInCodePayload') {
        onSuccess(normalizedValue, res.codeLength);
      }
    } catch (err) {
      setError(err);
    }
  };

  const submitDisabled = normalizedValue.length !== 11 || mutationState.fetching || !isReady;

  const agreementLink = (
    <Link to="/agreement">
      <p className="text-xs text-center text-gray-200 hover:text-gray-100 duration-200">
        Пользовательское соглашение и политика <br /> обработки персональных данных
      </p>
    </Link>
  );

  const errorView = error && <p className="text-orange-50 text-xs text-center">{error.message}</p>;

  const isCooldown =
    mutationState.data?.result.__typename === 'ErrorPayload' &&
    mutationState.data?.result.message.includes('повторить новый через минуту');

  return (
    <form onSubmit={submit} className="bg-white lg:min-h-full flex rounded-md">
      <div className="flex flex-col items-center py-8 px-10 m-auto lg:w-full">
        <p className="font-bold text-xl text-center px-2">Введите номер телефона, чтобы совершать заказы:</p>
        <div className="flex flex-col items-center" style={{ width: '278px' }}>
          <p className="border-b-2 border-gray-700 pb-2.5 mb-6 text-center mt-12 w-full">Россия</p>
          <div className="flex items-center justify-between w-full mb-1">
            <div
              className="rounded-md px-6 flex items-center justify-center placeholder-gray-200 bg-gray-400 mr-3"
              style={{ height: '48px' }}
            >
              +7
            </div>
            <input
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Номер телефона"
              className="rounded-md px-5 flex items-center justify-center placeholder-gray-200 bg-gray-400 duration-100 focus:ring-1 focus:ring-cyan-50"
              style={{ width: '197px', height: '48px' }}
            />
          </div>
          <button
            type="button"
            className="mt-3 mb-5 mr-auto text-sm opacity-70 hover:opacity-100 transition-opacity"
            onClick={signInByPassword}
          >
            Войти по паролю
          </button>
        </div>
        <div className="flex flex-col items-center">
          <Countdown
            currentValue={isCooldown ? 60 : 0}
            renderProcess={(time) => (
              <>
                {errorView}
                <p className="my-2 text-center text-xs">Вы сможете запросить код повторно через {time} сек.</p>
                {agreementLink}
                <ProgressButton disabled type="submit" isProgress={false} styles={sbClassName}>
                  Далее
                </ProgressButton>
              </>
            )}
            renderPending={() => (
              <>
                {!isCooldown && errorView}
                {agreementLink}
                <ProgressButton
                  disabled={submitDisabled}
                  type="submit"
                  isProgress={mutationState.fetching || !isReady}
                  styles={sbClassName}
                >
                  Далее
                </ProgressButton>
              </>
            )}
          />
        </div>
      </div>
    </form>
  );
};

gql`
  mutation AuthEnterPhoneMutation($input: RequestSignInCodeInput!) {
    result: requestSignInCode(input: $input) {
      ... on RequestSignInCodePayload {
        codeLength
        phone
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
