import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';

import { TimeDelivery_Data } from 'generated/graphql';
import { useStoreon } from 'hooks';
import { UserActiveAddress } from 'components/UserActiveAddress';
// import TimeIcon from 'components/icons/Time';
import { formatDay } from '../utils';
import * as s from './TimeDelivery.module.css';

const labels: { [key in string]: string } = {
  '0': 'сегодня',
  '1': 'завтра',
  '2': 'послезавтра',
};

export const useNextDelivery = () => {
  const { appData } = useStoreon('appData');
  const {nearestDeliveryInterval} = appData.viewer;

  const data = useStaticQuery<TimeDelivery_Data>(graphql`
    query TimeDelivery_data {
      viewer {
        nearestDeliveryInterval {
          fromTime
          value
        }
      }
    }
  `);

  const d = nearestDeliveryInterval || data.viewer.nearestDeliveryInterval;
  if (!d) {
    return null;
  }

  const diff = differenceInCalendarDays(new Date(d.value), new Date());
  const label = labels[diff] || formatDay(d?.value);
  return {
    label,
    fromTime: d.fromTime,
  };
};

export const TimeDelivery = () => {
  const timeDelivery = useNextDelivery();
  const { inApp } = useStoreon('inApp');

  if (inApp || !timeDelivery) return null;
  // const { label, fromTime } = timeDelivery;

  return (
    <div className="w-full bg-red-200 lg:bg-orange-100 lg:pt-14 pt-[40px] lg:hidden">
      <div
        className={`container leading-6 flex items-end lg:justify-center h-12 pb-3 -mt-12 lg:h-8 lg:-mt-8 lg:pb-2 ${s.deliverySize}`}
      >
        {/* <div className="flex items-center uppercase font-semibold tracking-wider border-r border-gray-500 pr-4 mr-5 lg:border-0 lg:mr-0 lg:pr-0">
          <div className="lg:hidden">
            <TimeIcon size={18} className="mr-5" />
          </div>
          <span className="leading-none">Ближайшая доставка</span>
          <span className="bg-orange-50 mx-2 px-1.5 rounded leading-4 text-white tracking-widest">
            {label} В {fromTime}
          </span>
        </div> */}
        <div className="lg:hidden">
          <UserActiveAddress type="timeDelivery" />
        </div>
      </div>
    </div>
  );
};
