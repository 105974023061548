import * as React from 'react';
import { Link } from 'gatsby';
import { useStoreon } from 'hooks';
import { useUserResolver } from './Auth';

type Props = {
  to?: string;
  classNames?: string;
  activeClassName?: string;
};

export const AuthRequiredLink: React.FC<Props> = ({ children, to, classNames, activeClassName, ...rest }) => {
  const { user } = useStoreon('user');
  const [getUser, signInModal] = useUserResolver();

  if (user && to) {
    return (
      <Link to={to} className={classNames} activeClassName={activeClassName} {...rest}>
        {children}
      </Link>
    );
  }

  const login = async () => {
    await getUser();
  };

  return (
    <>
      {signInModal}
      <button type="button" onClick={login} className={classNames} {...rest}>
        {children}
      </button>
    </>
  );
};
