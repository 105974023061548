import { useChatQuery, useChatSubscription } from 'generated/graphql';
import * as React from 'react';
import { gql } from 'urql';
import { Transition, TransitionStatus } from 'react-transition-group';
import { useStoreon } from 'hooks';
import CloseWhite from './icons/CloseWhite';
import * as s from './Chat.module.css';
import { ChatButton } from './ChatButton';
import { ChatForm } from './ChatForm';
import { ChatBody } from './ChatBody';

type WrapperProps = {
  opened: boolean;
  onClose: () => void;
};

const defaultStyle: React.CSSProperties = {
  transition: `all 200ms ease-in-out`,
  transform: 'translateY(30px)',
  visibility: 'hidden',
  opacity: 0,
};

const transitionStyles: { [key in TransitionStatus]: React.CSSProperties } = {
  entering: { opacity: 1, transform: 'translateY(0)', visibility: 'inherit' },
  entered: { opacity: 1, transform: 'translateY(0)', visibility: 'inherit' },
  exiting: {},
  exited: {},
  unmounted: {},
};

const ChatHeader = ({ closeChat }: { closeChat: () => void }) => (
  <div className="bg-[#3047ec] text-white py-4 px-7 flex items-center justify-between">
    Чат{' '}
    <button type="button" onClick={closeChat} className="hidden lg:block">
      <CloseWhite size={18} />
    </button>
  </div>
);

const ChatCloseHandler = ({
  forwardRef,
  onClose,
}: {
  forwardRef: React.RefObject<HTMLDivElement>;
  onClose: () => void;
}) => {
  const [mounted, setMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }
    const handleClick = (e: MouseEvent) => {
      if (!forwardRef?.current?.contains(e.target as Node)) {
        onClose();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeyDown);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [forwardRef, mounted, onClose]);

  return null;
};

const ChatWrapper: React.FC<WrapperProps> = ({ children, opened, onClose }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  return (
    <>
      <Transition nodeRef={ref} timeout={200} in={opened} onEnter={(node: any) => node.offsetHeight}>
        {(state) => (
          <div
            ref={ref}
            style={{ boxShadow: `rgba(0, 0, 0, 0.16) 0px 5px 40px`, ...defaultStyle, ...transitionStyles[state] }}
            className={`${s.chat} w-full h-[704px] max-w-[376px] rounded-lg fixed right-5 bottom-24 bg-white  z-50 flex flex-col overflow-hidden flex-shrink-0 lg:top-0 lg:left-0 lg:bottom-0 lg:right-0 lg:h-[inherit] lg:rounded-none lg:max-w-[inherit]`}
          >
            {children}
          </div>
        )}
      </Transition>
      {opened && <ChatCloseHandler forwardRef={ref} onClose={onClose} />}
    </>
  );
};

const ChatContent = ({ opened, setOpen, token }: { token: string | null; opened: boolean; setOpen: () => void }) => {
  const { subscriptionConnected } = useStoreon('subscriptionConnected');
  const [error, setError] = React.useState<Error | null>(null);
  const [res] = useChatQuery({
    requestPolicy: 'network-only',
    pause: !token,
  });

  const [chatSubscribe] = useChatSubscription({ variables: { token: token || '' }, pause: !token });

  React.useEffect(() => {
    if (subscriptionConnected === false || chatSubscribe.error) {
      setError({
        name: 'Subscription',
        message: 'Не удалось подключиться к серверу. Попробуйте позже.',
      })
    } else {
      setError(null);
    }
  }, [subscriptionConnected, chatSubscribe.error])

  const messageList = res.data?.viewer.user?.chatMessages;

  return (
    <ChatWrapper opened={opened} onClose={setOpen}>
      <ChatHeader closeChat={setOpen} />
      <ChatBody
        loading={res.fetching && !res.data}
        token={token}
        messages={messageList || []}
        error={res.error || error}
      />
      {token && error?.name !== 'Subscription' && <ChatForm setError={setError} />}
    </ChatWrapper>
  );
};

export const Chat = ({ raiseChat }: { raiseChat?: boolean }) => {
  const { token } = useStoreon('token');
  const [open, setOpen] = React.useState(false);

  const toggle = () => setOpen((o) => !o);

  return (
    <>
      <ChatButton open={open} onClick={toggle} aboveBottomBar={raiseChat} />
      <ChatContent opened={open} setOpen={toggle} token={token} />
    </>
  );
};

gql`
  query ChatQuery {
    viewer {
      id
      user {
        id
        chatMessages {
          ...ChatBody_message
        }
      }
    }
  }

  subscription ChatSubscription($token: String!) {
    chatMessageSent(token: $token) {
      ...ChatBody_message
    }
  }
`;
