import * as React from 'react';
import { Transition } from 'react-transition-group';
import CloseIcon from './icons/Close';
import * as s from './Modal.module.css';

type Props = {
  readonly onClose: () => void;
  closing?: boolean;
  hideCloseBtn?: boolean;
};

const defaultStyle = {
  transition: `all ${200}ms linear`,
  overflow: 'auto',
};

const transformStyles: any = {
  entering: { transform: 'translateY(0)' },
  entered: { transform: 'translateY(0)' },
  exiting: { transform: 'translateY(75px)' },
  exited: { transform: 'translateY(75px)' },
};

const opacityStyles: any = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

type WrapperType = {
  children: React.ReactNode;
  classNames: string;
  open: boolean;
  overlayRef: React.RefObject<HTMLDivElement>;
};

const ModalAnimationWrapper = ({ children, open, classNames, overlayRef }: WrapperType) => (
  <Transition classNames="modalWrapper" nodeRef={overlayRef} timeout={200} in={open}>
    {(state) => (
      <div
        ref={overlayRef}
        className={`${s.root} overflow-y-hidden`}
        style={{ ...defaultStyle, ...opacityStyles[state] }}
      >
        <div style={{ ...defaultStyle, ...transformStyles[state] }} className={classNames}>
          {children}
        </div>
      </div>
    )}
  </Transition>
);

export const Modal: React.FC<Props> = ({ onClose, children, closing, hideCloseBtn }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = React.useCallback((e: MouseEvent) => {
    if (e.target === ref.current) {
      onClose();
    }
  }, [onClose]);

  const handleKeyDown = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    if (closing) {
      setOpen(false);
    }
  }, [closing]);

  React.useEffect(() => {
    const y = window.scrollY;
    const scrollBarWidth = `${window.innerWidth - document.body.clientWidth}px`;
    const header = document.querySelector('header');

    document.body.classList.add('overflow-y-hidden', 'h-full');
    document.body.style.paddingRight = scrollBarWidth;
    if (header) header.style.paddingRight = scrollBarWidth;
    document.body.scrollTop = y;

    document.addEventListener('click', handleClick);
    window.addEventListener('keydown', handleKeyDown);
    setOpen(true);
    return () => {
      document.documentElement.classList.remove('overflow-y-scroll', 'h-full');
      document.body.classList.remove('overflow-y-hidden', 'h-full');
      document.body.style.paddingRight = '0';
      if (header) header.style.paddingRight = '0';

      document.removeEventListener('click', handleClick);
      window.removeEventListener('keydown', handleKeyDown);
      setOpen(false);

      window.scrollTo({ top: y });
    };
  }, [handleClick, handleKeyDown]);

  return (
    <ModalAnimationWrapper
      overlayRef={ref}
      open={open}
      classNames="relative m-auto lg:w-full lg:m-0 2xl:max-w-3/6 max-w-6/12"
    >
      {hideCloseBtn || (
        <button
          type="button"
          onClick={onClose}
          className="absolute right-2 top-2 flex items-center justify-center w-10 h-10 z-20 transition-opacity duration-300 ease-in-out hover:opacity-70"
        >
          <CloseIcon />
        </button>
      )}
      {children}
    </ModalAnimationWrapper>
  );
};
