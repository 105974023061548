import * as React from 'react';

export default function Basket() {
  return (
    <svg
      width="11px"
      height="15px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 15"
      className="
    transition-all duration-200 ease-linear
    text-gray-50 opacity-70 fill-current group-hover:text-orange-200 group-hover:opacity-100"
    >
      <path d="M.786 13.333c0 .917.707 1.667 1.571 1.667h6.286c.864 0 1.571-.75 1.571-1.667v-10H.786v10zM11 .833H8.25L7.464 0H3.536L2.75.833H0V2.5h11V.833z" />
    </svg>
  );
}
