import classNames from 'classnames';
import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string | null | undefined;
  onChangeText: (val: string) => void;
  className?: string;
  inputClassNames?: string;
  required?: boolean;
}

export const TextField = ({
  name,
  label,
  error,
  onChangeText,
  inputClassNames,
  required,
  className,
  ...props
}: Props) => {
  let inputClasses =
    'text-base bg-gray-100 py-2 px-2 w-full bg-gray-600 border-transparent transition-colors focus:border-cyan-300 placeholder-gray-50';
  if (inputClassNames) {
    inputClasses += ` ${inputClassNames}`;
  }
  return (
    <label className={classNames(className, 'block w-full my-2')} htmlFor={name}>
      <span className="mb-1 relative text-base lg:text-sm placeholder-gray-50">
        {label}
        {required && (
          <span style={{ color: 'red' }} className="absolute -top-1 -right-3">
            *
          </span>
        )}
      </span>
      <input
        id={name}
        name={name}
        required={required}
        onChange={(e) => onChangeText(e.target.value)}
        {...props}
        style={{ color: '#181818', borderRadius: 4, borderWidth: 1 }}
        className={inputClasses}
      />
      {error && <div className="text-red-50 text-sm">{error}</div>}
    </label>
  );
};
