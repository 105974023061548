import * as React from 'react';
import { navigate } from 'gatsby';
import { gql } from '@urql/core';
import { useForm, FieldError, FieldErrors, Resolver } from 'react-hook-form';
import { CreateAddressInput, useBasicCreateAddressMutation } from 'generated/graphql';
import { CreateAddressView } from 'components/CreateAddressView';

export type PrevAddress = {
  id: number;
} & CreateAddressInput;

type Props = {
  readonly onClose: () => void;
  readonly prevAddress?: PrevAddress;
  readonly navigateTo?: string;
  readonly className?: string;
  readonly hideTitle?: boolean;
  readonly title?: string;
};

let defaultValues: CreateAddressInput = {
  city: null,
  street: null,
  house: null,
  comment: null,
  entrance: null,
  building: null,
  buildingFloor: null,
  appartment: null,
  intercomCode: null,
  location: null,
};

type Validator = (values: CreateAddressInput) => ErrorData | null;

type ErrorData = {
  field: keyof CreateAddressInput;
  error: FieldError;
};

if (process.env.NODE_ENV !== 'production') {
  defaultValues = { ...defaultValues, city: 'Москва' };
}

const validators: Validator[] = [
  (values) => {
    if (values.city === null) {
      return {
        field: 'city',
        error: {
          type: 'required',
          message: 'поле является обязательным',
        },
      };
    }
    return null;
  },
  (values) => {
    if (values.street === null) {
      return {
        field: 'street',
        error: {
          type: 'required',
          message: 'поле является обязательным',
        },
      };
    }
    return null;
  },
];

const resolver: Resolver<CreateAddressInput> = async (values) => {
  const errorData: ErrorData[] = [];
  validators.forEach((v) => {
    const ed = v(values);
    if (ed) {
      errorData.push(ed);
    }
  });

  if (errorData.length > 0) {
    const errors: FieldErrors<CreateAddressInput> = {};
    errorData.reduce((_, e) => ({ ...errors, [e.field]: e.error }), {});
    return { errors, values: {} };
  }

  return { values, errors: {} };
};

export const CreateAddress = ({ onClose, navigateTo, className, hideTitle, prevAddress, title }: Props) => {
  const scrollTimeout = React.useRef<any>(null);
  const { id: parentId, ...address } = prevAddress || {};
  const { control, setError, handleSubmit } = useForm<CreateAddressInput>({
    defaultValues: parentId ? address : defaultValues,
    resolver,
  });

  const [mutationState, mutate] = useBasicCreateAddressMutation();

  React.useEffect(() => {
    const onLeave = () => clearTimeout(scrollTimeout.current);
    return onLeave;
  }, []);

  const create = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(async (input) => {
      try {
        const res = await mutate(parentId ? { parentId, ...input } : input);
        if (res.__typename === 'CreateAddressPayload') {
          if (navigateTo) {
            navigate(`/${navigateTo}`);
          }

          onClose();
        }
      } catch (err) {
        setError('city', { type: 'manual', message: err.message });
      }
    })();
  };

  return (
    <CreateAddressView
      control={control}
      onSubmit={create}
      className={className}
      hideTitle={hideTitle}
      loading={mutationState.fetching}
      title={title}
    />
  );
};

gql`
  mutation CreateAddressMutation($input: CreateAddressInput!) {
    result: createAddress(input: $input) {
      ... on ErrorPayload {
        message
      }

      ... on CreateAddressPayload {
        user {
          id
          ...UserActiveAddress_data
          ...CheckoutDeliveryAddress_data
        }
      }
    }
  }
`;
