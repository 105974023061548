import * as React from 'react';
import noImage from 'images/no-image.png';

type Props = {
  src: string;
} & Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

export const Image = ({ src, srcSet, alt, ...rest }: Props) => {
  const [imgSrc, setImgSrc] = React.useState({
    src,
    srcSet,
  });

  const onError = () => {
    setImgSrc((i) => ({
      src: noImage,
      srcSet: i.srcSet && noImage,
    }));
  };

  return <img src={imgSrc.src} srcSet={imgSrc.srcSet} onError={onError} alt={alt || ''} {...rest} />;
};
