import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';
import DotLoaderIcon from 'components/icons/DotLoader';

type Props = {
  readonly className?: string;
};

export const Loader = ({ className }: Props) => {
  const nodeRef = React.useRef(null);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    if (show) return undefined;

    const t = setTimeout(() => {
      setShow(true);
    }, 300);

    return () => clearTimeout(t);
  }, [show]);

  return (
    <CSSTransition nodeRef={nodeRef} in={show} classNames="loader" timeout={400}>
      <div ref={nodeRef} className={cn('loader', className)}>
        <DotLoaderIcon size={32} />
      </div>
    </CSSTransition>
  );
};
