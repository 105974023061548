import classNames from 'classnames';
import * as React from 'react';

export const Search = ({ className }: { className?: string }) => (
  <svg
    width="25"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('fill-current', className)}
  >
    <path d="M21.756 22.245a.7.7 0 0 0 .988-.99l-.988.99zm-4.287-5.267-.494-.495.494.495zM11.375 18.8a7.925 7.925 0 0 1-7.925-7.925h-1.4a9.325 9.325 0 0 0 9.325 9.325v-1.4zM3.45 10.875a7.925 7.925 0 0 1 7.925-7.925v-1.4a9.325 9.325 0 0 0-9.325 9.325h1.4zm7.925-7.925a7.925 7.925 0 0 1 7.925 7.925h1.4a9.325 9.325 0 0 0-9.325-9.325v1.4zm7.925 7.925a7.9 7.9 0 0 1-2.325 5.608l.989.99a9.298 9.298 0 0 0 2.736-6.598h-1.4zm-2.325 5.608a7.898 7.898 0 0 1-5.6 2.317v1.4a9.298 9.298 0 0 0 6.589-2.726l-.99-.991zm0 .99 4.78 4.772.99-.99-4.781-4.772-.99.99z" />
  </svg>
);
