import * as React from 'react';
import { allModals } from 'modals';
import { captureException } from 'utils';
import { useStoreon } from 'hooks';
import { Modal } from './Modal';
import { Modal as ModalTypes } from '../store';

export const ModalLogic = () => {
  const { modal, dispatch } = useStoreon('modal');
  const [closing, setClosing] = React.useState<boolean>(false);

  const onClose = React.useCallback(() => setClosing(true), []);

  React.useEffect(() => {
    const closeModal = () => {
      if (closing) {
        setClosing(false);
        dispatch('setModal', null);
      }
    };

    const t = setTimeout(closeModal, 200);

    return () => {
      clearTimeout(t);
    };
  }, [closing, dispatch]);

  if (!modal) {
    return null;
  }

  const Content = allModals[modal.type];

  if (!Content) {
    captureException(new Error(`${modal} modal not found`));
    return null;
  }

  const hideCloseBtn = modal.type === 'promoCodeActivated';

  return (
    <Modal onClose={onClose} closing={closing} hideCloseBtn={hideCloseBtn}>
      <Content onClose={onClose} {...modal} />
    </Modal>
  );
};

type ButtonProps = {
  readonly options: NonNullable<ModalTypes>;
  readonly className?: string;
};

export const ModalButton: React.FC<ButtonProps> = ({ options, children, className }) => {
  const { dispatch } = useStoreon();

  const onClick = () => {
    dispatch('setModal', options);
  };

  return (
    <button type="button" onClick={onClick} className={className}>
      {children}
    </button>
  );
};
