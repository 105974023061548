import * as React from 'react';
import cn from 'classnames';
import { ContentTitle } from 'components/ContentTitle';

type Props = {
  urlToSend: string;
  onClose: () => void;
};

export const ShareCart = ({ urlToSend, onClose }: Props) => {
  const [copied, setCopied] = React.useState(false);
  const copyToClipboard = () => {
    navigator.clipboard?.writeText(urlToSend).then(() => {
      setCopied(true);
    });
  };
  React.useEffect(() => {
    const handleCopied = () => {
      if (copied) {
        setCopied(false);
      }
    };

    const t = setTimeout(handleCopied, 3000);
    return () => clearTimeout(t);
  }, [copied]);

  const handleAreaClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    const target = event.target as HTMLTextAreaElement;
    target.select();
  };

  return (
    <div className="bg-white w-[1010px] lg:w-full flex rounded-lg lg:min-h-full">
      <div className="m-auto min-w-full">
        <ContentTitle title="Поделиться корзиной" className="pl-5 mt-5 mb-4" />

        <div className="px-5 pb-5">
          <div>Просто скопируйте ссылку и отправьте другу:</div>
          <div className="">
            <textarea
              style={{ color: '#181818', minHeight: 142, borderWidth: 1 }}
              className="w-full overflow-y-auto items-start text-base cursor-text rounded-md py-3 px-4 placeholder-gray-50 mt-2 bg-gray-600  border-transparent transition-colors hover:border-cyan-300 lg:py-2"
              onClick={handleAreaClick}
              onChange={(e) => e.preventDefault()}
              value={urlToSend}
            />
            {navigator.clipboard && (
              <>
                <button
                  className={cn(
                    'bg-orange-50 text-white rounded-md px-3 py-2 hover:bg-[#FA733A] transition-colors duration-200',
                    // copied ? 'bg-[#EAF9DE] text-black' : '',
                  )}
                  type="button"
                  onClick={copyToClipboard}
                >
                  Копировать
                </button>
                <span
                  className={cn(
                    'text-sm text-[#229022] opacity-0 transition-opacity duration-300 mt-1 ml-1',
                    copied ? 'opacity-100' : '',
                  )}
                >
                  Скопировано!
                </span>
              </>
            )}
          </div>
          <button
            type="button"
            className="bg-orange-50 text-white px-3 py-2 rounded-md ml-auto block mt-10 hover:bg-[#FA733A] transition-colors duration-200"
            onClick={onClose}
          >
            Закрыть
          </button>
        </div>
      </div>
    </div>
  );
};
