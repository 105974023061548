import { Link } from '@reach/router';
import { AppDataViewer, useSignInByPassword } from 'generated/graphql';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { gql } from 'urql';
import { normalizePhone } from 'utils';
import { ProgressButton } from './ProgressButton';

type Props = {
  signInByCode: () => void;
  onSuccess: (token: string, viewer: AppDataViewer) => void;
};

export const AuthByPassword = ({ signInByCode, onSuccess }: Props) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [res, signInMutate] = useSignInByPassword();

  const { control, handleSubmit, formState } = useForm<{ phone: string; password: string }>({
    defaultValues: {
      phone: process.env.NODE_ENV === 'production' ? '' : '9999999999',
      password: '',
    },
  });
  const { errors } = formState;
  const onSubmit = handleSubmit(async (values) => {
    setError(null);
    try {
      const signInRes = await signInMutate({
        input: {
          ...values,
          phone: normalizePhone(values.phone),
        },
      });
      if (signInRes.error) {
        throw signInRes.error;
      }

      if (signInRes.data?.signInByPassword.__typename === 'ErrorPayload') {
        throw new Error(signInRes.data.signInByPassword.message);
      }
      if (signInRes.data?.signInByPassword.__typename === 'SignInPayload') {
        const { token, viewer } = signInRes.data.signInByPassword;
        onSuccess(token, viewer);
      }
    } catch (e) {
      // eslint-disable-next-line no-alert
      setError(e);
    }
  });

  const errorView = error && <div className="text-red-50 text-sm mt-2">{error.message}</div>;

  return (
    <form onSubmit={onSubmit} className="bg-white lg:min-h-full flex rounded-md">
      <div className="m-auto flex flex-col items-center justify-center bg-white py-8 px-10 rounded-md lg:w-full lg:h-full  w-screen max-w-[600px]">
        <p className="font-bold text-center text-xl px-2">Войдите, чтобы совершать заказы:</p>
        <div className="flex flex-col items-center" style={{ width: '278px' }}>
          <p className="border-b-2 border-gray-700 pb-2.5 mb-6 text-center mt-12 w-full">Россия</p>
          <div className="mb-2">
            <div className="flex items-center justify-between w-full">
              <div
                className="rounded-md px-6 flex items-center justify-center placeholder-gray-200 bg-gray-400 mr-3"
                style={{ height: '48px' }}
              >
                +7
              </div>
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: 'Введите номер',
                  validate: (v) => {
                    if (normalizePhone(v).length !== 11) return 'Номер телефона имеет неверный формат';
                    return true;
                  },
                }}
                render={({ field }) => (
                  <input
                    type="text"
                    value={field.value}
                    onChange={field.onChange}
                    placeholder="Номер телефона"
                    className="rounded-md px-5 flex items-center justify-center placeholder-gray-200 bg-gray-400 duration-100 focus:ring-1 focus:ring-cyan-50"
                    style={{ width: '197px', height: '48px' }}
                  />
                )}
              />
            </div>
            {errors.phone && <div className="text-red-50 text-sm mt-2">{errors.phone.message}</div>}
          </div>
          <div className="w-full">
            <div className="mr-auto mb-1 text-sm">Пароль:</div>
            <Controller
              control={control}
              name="password"
              rules={{
                required: 'Введите пароль',
              }}
              render={({ field }) => (
                <input
                  type="text"
                  value={field.value}
                  onChange={field.onChange}
                  placeholder="Введите пароль"
                  className="rounded-md px-5 flex items-center justify-center  placeholder-gray-200 bg-gray-400 duration-100 focus:ring-1 focus:ring-cyan-50"
                  style={{ width: '100%', height: '48px' }}
                />
              )}
            />
            {errors.password && <div className="text-red-50 text-sm mt-2">{errors.password.message}</div>}
          </div>
          {errorView}
          <button
            type="button"
            className="my-3 mr-auto text-sm opacity-70 hover:opacity-100 transition-opacity"
            onClick={signInByCode}
          >
            Войти по коду
          </button>
          <Link to="/agreement">
            <p className="text-xs text-center text-gray-200 hover:text-gray-100 duration-200">
              Пользовательское соглашение и политика <br /> обработки персональных данных
            </p>
          </Link>
          <ProgressButton
            type="submit"
            isProgress={res.fetching}
            styles="btn-orange h-14 px-20 mt-4 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide"
          >
            Войти
          </ProgressButton>
        </div>
      </div>
    </form>
  );
};

gql`
  mutation SignInByPassword($input: SignInByPasswordInput!) {
    signInByPassword(input: $input) {
      ... on SignInPayload {
        token
        viewer {
          id
          ...AppDataViewer
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
