import * as React from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { getExtraDryOrderData, useDryOrder, useStoreon } from 'hooks';
import { HomeHeaderQuery } from 'generated/graphql';
import { gql } from 'urql';
import classNames from 'classnames';

import LogoImg from './icons/logo.svg';
import ClockImg from './icons/ClockOrange';
import CatalogIcon from './icons/Catalogue';
import SearchIcon from './icons/SearchHome';
import WarningIcon from './icons/Warning';
import { User } from './icons/UserOutlined';
import { Bag } from './icons/BagOutlined';
import { Burger } from './icons/BurgerOutlined';
import { Search as SearchOutlined } from './icons/SearchOutlined';
import { AuthRequiredLink } from './AuthRequiredLink';
import { DropDown } from './DropDown';
import { useNextDelivery } from './TimeDelivery';
import { SignInButton } from './SignInButton';
import { Heart } from './icons/HeartOutlined';

import * as s from './Header.module.css';
import { MobileMenu } from './MobileMenu';

const navMenu = [
  {
    label: 'Каталог',
    to: '/catalog',
  },
  {
    label: 'О нас',
    to: '/about',
  },
  {
    label: 'FAQ',
    to: '/faq',
  },
  {
    label: 'О проекте',
    to: '/about-app',
  },
  {
    label: 'Партнерам',
    to: '/partners',
  },
  {
    label: 'Курьеры',
    to: '/delivery/form',
  },
  {
    label: 'Возврат',
    to: '/returns',
  },
  {
    label: 'Оплата',
    to: '/payment',
  },
];

type ButtonProps = {
  name: string;
  icon: JSX.Element;
  badgeContent?: string | number | null;
};

const ButtonContent = ({ name, icon, badgeContent }: ButtonProps) => (
  <>
    {icon}
    <span className="block text-xs">{name}</span>
    {badgeContent && (
      <span className="text-[10px] text-white bg-orange-50 rounded-[15px] border-2 border-white h-4.5 px-1 min-w-[21px] flex items-center justify-center absolute top-0 left-1/2 whitespace-nowrap">
        {badgeContent}
      </span>
    )}
  </>
);

const Search = ({ className = '' }: { className?: string }) => {
  const [searchTerm, setSearchTerm] = React.useState('');

  React.useEffect(() => {
    if (!searchTerm.trim()) return undefined;

    const t = setTimeout(() => {
      navigate(`/search?q=${searchTerm}`);
    }, 2000);

    return () => clearTimeout(t);
  }, [searchTerm]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);
  return (
    <div className={`flex py-3 px-4 bg-gray-400 rounded w-full max-w-[593px] ${className}`}>
      <SearchIcon className="mr-2 opacity-70" />
      <input
        value={searchTerm}
        onChange={onChange}
        placeholder="Поиск"
        className="bg-transparent text-[#666] placeholder-[#666] w-full"
      />
    </div>
  );
};

const BottomRight = () => (
  <>
    <div className="lg:hidden">
      <Link className="hover:text-orange-50 transition-colors mr-10" to="/about">
        О нас
      </Link>
      <Link to="/payment" className="hover:text-orange-50 transition-colors mr-10">
        Доставка и оплата
      </Link>
      {/* <Link to="/" className="hover:text-orange-50 transition-colors mr-10">
        Депозит
      </Link>
      <Link to="/" className="hover:text-orange-50 transition-colors mr-10">
        Контакты
      </Link> */}
      <Link to="/partners" className="hover:text-orange-50 transition-colors">
        Для бизнеса
      </Link>
    </div>
    <Search className="hidden lg:flex" />
  </>
);

const CartBtn = ({ className }: { className: string }) => {
  const [res] = useDryOrder();
  const extra = getExtraDryOrderData(res);
  return (
    <Link id="cartIcon" className={classNames('transition-all', className)} to="/cart">
      <ButtonContent icon={<Bag className="mb-1" />} name="Корзина" badgeContent={extra.itemCount || null} />
    </Link>
  );
};

const FavoriteBtn = ({ className }: { className: string }) => {
  const { user } = useStoreon();
  const favoriteProducts = user?.favoriteProductIds || null;
  return (
    <AuthRequiredLink classNames={className} to="/my/favorites">
      <ButtonContent badgeContent={favoriteProducts?.length || null} icon={<Heart className="mb-1" />} name="Любимое" />
    </AuthRequiredLink>
  );
};

const DropDownBtn = ({ className }: { className: string }) => (
  <DropDown
    ariaPrefix="menu"
    items={navMenu}
    className="flex-col items-center justify-center relative z-0 hidden lg:flex"
  >
    {() => (
      <span className={classNames('block', className)}>
        <ButtonContent icon={<Burger className="mb-1" />} name="Меню" />
      </span>
    )}
  </DropDown>
);

const SearchBtn = ({ className }: { className: string }) => (
  <Link className={className} to="/search?q=">
    <ButtonContent icon={<SearchOutlined className="mb-1" />} name="Поиск" />
  </Link>
);

const getMyAddressesLabel = (user: any) => {
  if (user.addresses.length === 0) {
    return 'Добавить адрес';
  }

  return user.activeAddress ? 'Мои адреса' : 'Выбрать адрес';
};

const getUserMenu = (user: any, handleQuit: () => void) => {
  const userMenu = [
    {
      label: 'Мои заказы',
      to: '/my/orders',
    },
    {
      label: getMyAddressesLabel(user),
      to: '/my/addresses',
      icon: user.addresses.length === 0 || !user.activeAddress ? <WarningIcon size={20} /> : null,
    },
    {
      label: 'Купленные продукты',
      to: '/my/bought',
    },
    user.depositBalance > 0 && {
      label: 'Депозит',
      to: '/my/deposit',
    } || null,
    {
      label: 'Настройки',
      to: '/my/settings',
      icon: !user.name && !user.email ? <WarningIcon size={20} /> : null,
    },
    {
      label: 'Выйти',
      onClick: handleQuit,
    },
  ];

  let dropDownClasses: string = '';

  if (user.addresses.length === 0 || !user.name || !user.email) {
    dropDownClasses += ` ${s.warningPersonMenu}`;
  }

  return (
    <div style={{ color: 'inherit' }} className="w-16 h-16 flex flex-col justify-center items-center relative">
      <DropDown ariaPrefix="user" items={userMenu} className={dropDownClasses}>
        {() => (
          <span className="flex flex-col justify-center items-center">
            <User />
            <span className="text-xs mt-1">Кабинет</span>
          </span>
        )}
      </DropDown>
    </div>
  );
};

const UserBtn = ({ className }: { className: string }) => {
  const { user, dispatch } = useStoreon('user');
  const handleQuit = () => {
    // eslint-disable-next-line
    if (confirm('Вы уверены, что хотите выйти из аккаунта?')) {
      // eslint-disable-next-line no-console
      console.log('reset token: after handle quit at HomeHeader');
      dispatch('setToken', null);
    }
  };

  return (
    <div className={className}>
      {user ? (
        getUserMenu(user, handleQuit)
      ) : (
        <SignInButton classNames="flex w-16 h-16 flex-col justify-center items-center">
          <User />
          <span className="text-xs mt-1">Войти</span>
        </SignInButton>
      )}
    </div>
  );
};

const MobileMenuBtn = ({ className }: { className: string }) => (
  <MobileMenu className={classNames('block', className)}>
    {() => <ButtonContent icon={<Burger className="mb-1" />} name="Меню" />}
  </MobileMenu>
);

const TopRight = () => {
  const buttonCn = `w-16 h-16 flex flex-col justify-center items-center relative transition-colors duration-200 ease-linear hover:text-orange-50 lg:hover:text-black`;

  return (
    <>
      <div className="flex ml-16 flex-shrink-0 lg:ml-0 group ">
        <UserBtn className={classNames('sm:hidden', buttonCn)} />
        <FavoriteBtn className={classNames('lg:hidden ', buttonCn)} />
        <SearchBtn className={classNames('hidden sm:flex', buttonCn)} />
        <DropDownBtn className={classNames('sm:hidden', buttonCn)} />
        <CartBtn className={buttonCn} />
        <MobileMenuBtn className={classNames('hidden sm:flex', buttonCn)} />
      </div>
    </>
  );
};

const NextDelivery = ({ className = '' }: { className?: string }) => {
  const timeDelivery = useNextDelivery();
  if (!timeDelivery) return null;
  const { label, fromTime } = timeDelivery;
  return (
    <div className={`flex mr-16 flex-shrink-0 lg:mr-0 ${className}`}>
      <ClockImg className="mr-2" />
      <div>
        <div className="text-base">
          Ближайшая доставка:{' '}
          <span className="text-orange-50">
            {label} в {fromTime}
          </span>
        </div>
        <div className="text-sm text-[#666] sm:hidden">Доставка продуктов в Москве и за МКАД</div>
      </div>
    </div>
  );
};

const FixedHeader = ({ className }: { className: string }) => (
  <header
    className={classNames(className, 'fixed left-0 right-0 top-0 z-50 bg-white sm:border-b-[1px] border-[#ebebeb]')}
  >
    <div className="container px-2 h-full flex items-center sm:flex-col sm:justify-between pt-3 pb-2.5 sm:px-3">
      <div className="flex justify-between items-center  flex-shrink-0 w-full">
        <Link to="/" className="mr-5 flex-shrink-0">
          <img src={LogoImg} alt="" className="sm:w-[100px]" />
        </Link>
        <NextDelivery className="sm:hidden" />
        <Search className="lg:hidden" />
        <TopRight />
      </div>
      <NextDelivery className="hidden sm:flex justify-center w-full" />
    </div>
  </header>
);

export const HomeHeader = () => {
  const { inApp } = useStoreon('inApp');

  const data = useStaticQuery<HomeHeaderQuery>(graphql`
    query HomeHeaderQuery {
      menuCategories {
        id
        name
        pageUrl
      }
    }
  `);

  if (inApp) return null;

  // sizes for fixed header
  const cn = 'h-[96px] sm:h-[120px]';

  return (
    <>
      <FixedHeader className={cn} />
      <div className={cn} />

      <div className="border-b-[1px] border-[#ebebeb] sm:border-b-0 sm:hidden">
        <div className="container px-2">
          <div className="py-4 flex items-center justify-between sm:py-0">
            <div className="flex items-center justify-between w-full">
              <div className="flex items-center">
                <Link
                  to="/catalog"
                  className="py-3 px-5 text-white bg-orange-50 flex items-center justify-center rounded text-base font-semibold hover:bg-[#FA733A] transition-colors duration-200"
                >
                  <CatalogIcon className="mr-2" />
                  <span>Каталог</span>
                </Link>
                {data &&
                  data.menuCategories.slice(0, 2).map(({ id, name, pageUrl }) => (
                    <Link
                      key={id}
                      to={pageUrl}
                      className="text-base px-6 py-3 ml-2 text-orange-50 font-medium hover:text-[#FA733A] transition-colors"
                    >
                      {name}
                    </Link>
                  ))}
              </div>
              <BottomRight />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const query = gql`
  fragment HomeHeader_user on User {
    depositBalance
    favoriteProductIds
    addresses {
      id
    }
    name
    email
    activeAddress {
      ...Address_data
    }
  }
`;
