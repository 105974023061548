export const animateProductToCart = (productCardRef: React.MutableRefObject<null | HTMLDivElement>) => () => {
  const cart = document.getElementById('cartIcon');

  if (!cart || !productCardRef?.current) return null;
  const cardImage = productCardRef.current.getElementsByTagName('img')[0];
  const cardImagePosition = cardImage.getBoundingClientRect();
  const cartPosition = cart.getBoundingClientRect();
  if (!cardImage.parentElement) return null;

  // Wrapper crops the image if it's rectangular
  const elWrapper = document.createElement('div');
  const el = cardImage.cloneNode(true) as typeof cardImage;

  const leftPos = cardImagePosition.left + (cardImagePosition.width - cardImagePosition.height) / 2;
  elWrapper.classList.add('ghost');
  elWrapper.style.top = `${cardImagePosition.top.toString()}px`;
  elWrapper.style.left = `${leftPos.toString()}px`;
  elWrapper.style.width = `${cardImagePosition.height.toString()}px`;
  elWrapper.style.height = `${cardImagePosition.height.toString()}px`;

  el.style.minWidth = `${cardImagePosition.width.toString()}px`;
  el.style.height = `${cardImagePosition.height.toString()}px`;
  elWrapper.appendChild(el);
  document.body.appendChild(elWrapper);

  // shift
  const dx = cartPosition.x - cardImagePosition.x - (cardImagePosition.width - cartPosition.width) / 2;
  const dy = cartPosition.y - cardImagePosition.y - (cardImagePosition.height - cartPosition.height) / 2;

  // scale
  const sx = cartPosition.width / cardImagePosition.width;
  const sy = cartPosition.height / cardImagePosition.height;

  setTimeout(() => {
    elWrapper.style.transform = `translate(${dx}px, ${dy}px) scale(${sx}, ${sy})`;
    elWrapper.style.opacity = '0';
    cart.style.transform = 'scale(1.1)';
  }, 50);

  setTimeout(() => {
    cart.style.transform = 'inherit';
    document.body?.removeChild(elWrapper);
  }, 400);
  return null;
};
