import { SupportModal } from './Support';
import { ChangeActiveAddress } from './ChangeActiveAddress';
import { CreateAddress } from './CreateAddress';
import { EnterPromoCode } from './EnterPromoCode';
import { ChangeProductReview } from './ChangeProductReview';
import { ChangePaymentMethod } from './ChangePaymentMethod';
import { DeliveryTimePicker } from './DeliveryTimePicker';
import { AddToCart } from './AddToCart';
import { ShareCart } from './ShareCart';
import { Modal } from '../store';
import { PromoCodeActivated } from './PromoCodeActivated';
import { UpdatePassword } from './UpdatePassword';

type Names = Pick<Modal, 'type'>['type'];

// TODO: remove any!
export const allModals: { [key in Names]: any } = {
  support: SupportModal,
  changeActiveAddress: ChangeActiveAddress,
  createAddress: CreateAddress,
  enterPromoCode: EnterPromoCode,
  changeProductReview: ChangeProductReview,
  changePaymentMethod: ChangePaymentMethod,
  deliveryTimePicker: DeliveryTimePicker,
  addToCart: AddToCart,
  shareCart: ShareCart,
  promoCodeActivated: PromoCodeActivated,
  updatePassword: UpdatePassword,
};
