import * as React from 'react';
import { Link } from 'gatsby';
import { gql } from '@urql/core';
import {
  useBasicAuthEnterCodeMutation,
  AppDataViewer,
  useBasicAuthEnterPhoneMutation,
  AuthEnterPhoneMutation,
} from 'generated/graphql';
import { userLogin, userReg } from 'analytics';
import { UseMutationState } from 'urql';
import { formatPhone, normalizePhone } from 'utils';
import EditIcon from './icons/Edit';
import { Countdown } from './Countdown';
import { ProgressButton } from './ProgressButton';

type Props = {
  phone: string;
  codeLength: number;
  onSuccess: (token: string, viewer: AppDataViewer) => void;
  onCancel: () => void;
};

type ButtonProps = {
  codeRes: UseMutationState<AuthEnterPhoneMutation>;
  repeatedRequest: () => void;
  error: Error | null;
};

const CodeRefetchButton = (props: ButtonProps) => {
  const { codeRes, repeatedRequest, error } = props;

  if (codeRes.fetching) {
    return <div className="mt-3">Отправляем код...</div>;
  }

  return (
    <Countdown
      currentValue={60}
      renderProcess={(countdown) => (
        <div className="text-sm text-center mt-4">
          {!error ? <>Код отправлен.&nbsp;</> : null}
          Вы сможете запросить код повторно через {countdown} сек.
        </div>
      )}
      renderPending={() => (
        <button
          type="button"
          onClick={repeatedRequest}
          className="mt-3 text-cyan-50 duration-300 hover:text-opacity-70"
        >
          Отправить еще раз
        </button>
      )}
    />
  );
};

export const AuthEnterCode = ({ phone, codeLength, onCancel, onSuccess }: Props) => {
  const [error, setError] = React.useState<Error | null>(null);
  const [value, setValue] = React.useState(process.env.NODE_ENV === 'production' ? '' : '00000');
  const [mutationState, mutate] = useBasicAuthEnterCodeMutation();
  const [requestCodeRes, requestCode] = useBasicAuthEnterPhoneMutation();

  const normalizedValue = normalizePhone(value);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    try {
      const res = await mutate({ phone, code: normalizedValue });
      if (res.__typename === 'SignInPayload') {
        onSuccess(res.token, res.viewer);

        if (res.isFirstSignIn) {
          userReg();
        } else {
          userLogin();
        }
      }
    } catch (err) {
      setError(err);
    }
  };

  const repeatedReq = async () => {
    setError(null);

    try {
      await requestCode({ phone });
    } catch (err) {
      setError(err);
    }
  };

  const buttonDisabled = normalizedValue.length !== codeLength || mutationState.fetching || requestCodeRes.fetching;

  return (
    <form onSubmit={submit} className="bg-white lg:min-h-full flex rounded-md">
      <div className="m-auto flex flex-col items-center justify-center bg-white py-8 px-10 rounded-md lg:w-full lg:h-full">
        <div className="text-center">
          <p className="font-bold text-center text-xl px-2">Введите номер телефона, чтобы совершать заказы:</p>
          <div className="mt-5 flex items-center justify-center">
            <div className="rounded-md px-6 flex items-center justify-center placeholder-gray-200 bg-gray-400 mr-3 h-[48px]">
              +7
            </div>
            <p className="rounded-md px-5 flex items-center justify-center w-[197px] h-[48px] bg-gray-400 whitespace-nowrap xs:w-[150px]">
              {formatPhone(phone).replace('+7 ', '')}
            </p>
            <button
              type="button"
              className="rounded-md px-5 flex items-center justify-center ml-3 bg-gray-400 h-[48px]"
              onClick={onCancel}
            >
              <EditIcon />
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center my-8">
          <p className="text-center mb-3">
            Для подтверждения номера <br /> введите код из SMS:
          </p>
          {error && <p className="mb-2 text-orange-50">{error.message}</p>}
          <input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder="Введите код"
            className="rounded-md px-5 flex items-center justify-center text-center placeholder-gray-200 bg-gray-400 duration-100 focus:ring-1 focus:ring-cyan-50"
            style={{ width: '140px', height: '48px' }}
          />
          <CodeRefetchButton codeRes={requestCodeRes} repeatedRequest={() => repeatedReq()} error={error} />
        </div>
        <div className="flex flex-col items-center">
          <Link to="/agreement">
            <p className="text-xs text-center text-gray-200 hover:text-gray-100 duration-200">
              Пользовательское соглашение и политика <br /> обработки персональных данных
            </p>
          </Link>
          <ProgressButton
            type="submit"
            disabled={buttonDisabled}
            isProgress={mutationState.fetching || requestCodeRes.fetching}
            styles="btn-orange h-14 px-20 mt-4 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide"
          >
            Отправить
          </ProgressButton>
        </div>
      </div>
    </form>
  );
};

export const graphqlSpec = gql`
  mutation AuthEnterCodeMutation($input: SignInByCodeInput!) {
    result: signInByCode(input: $input) {
      ... on SignInPayload {
        token
        isFirstSignIn
        viewer {
          ...AppDataViewer
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
