import * as React from 'react';
import cn from 'classnames';

type Props = {
  readonly error: Error;
  readonly retry: () => void;
  readonly className?: string;
};

export const PageError = ({ error, retry, className }: Props) => (
  <div className={cn('flex flex-1 flex-col items-center', className)}>
    <p className="font-bold text-lg">Произошла ошибка: {error.message}</p>
    <button
      onClick={retry}
      type="button"
      className="uppercase font-medium px-5 rounded-md block mt-4 bg-orange-200 py-4 text-white"
    >
      Попробовать еще раз
    </button>
  </div>
);
