import classNames from 'classnames';
import * as React from 'react';

export const Bag = ({ className }: { className: string }) => (
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('stroke-current fill-current', className)}
  >
    <path
      d="M17 21.05H7v1.4h10v-1.4zm3.3-14.8v11.5h1.4V6.25h-1.4zm-18 0v11.5h1.4V6.25H2.3zM17 1.55H7v1.4h10v-1.4zm4.7 4.7a4.7 4.7 0 0 0-4.7-4.7v1.4a3.3 3.3 0 0 1 3.3 3.3h1.4zm-18 0A3.3 3.3 0 0 1 7 2.95v-1.4a4.7 4.7 0 0 0-4.7 4.7h1.4zM7 21.05a3.3 3.3 0 0 1-3.3-3.3H2.3a4.7 4.7 0 0 0 4.7 4.7v-1.4zm10 1.4a4.7 4.7 0 0 0 4.7-4.7h-1.4a3.3 3.3 0 0 1-3.3 3.3v1.4z"
      fill="inherit"
      style={{ strokeWidth: 0 }}
    />
    <path
      d="M7.5 6v1.5a4.5 4.5 0 0 0 9 0V6"
      style={{ stroke: 'inherit', fill: '#fff' }}
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
);
