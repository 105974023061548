import * as React from 'react';
import { gql } from '@urql/core';
import { useBasicEnterPromoCodeMutation, CheckPromoCodeInput } from 'generated/graphql';
import { useStoreon } from 'hooks';
import { useForm } from 'react-hook-form';

import CheckIcon from 'components/icons/Check';
import { ProgressButton } from '../components/ProgressButton';

type Props = {
  onClose: () => void;
};

export const EnterPromoCode = ({ onClose }: Props) => {
  const { promoCode: currentPromoCode, dispatch } = useStoreon('promoCode');
  const defaultValues = { value: currentPromoCode || undefined };

  const { register, handleSubmit, formState } = useForm<CheckPromoCodeInput>({
    defaultValues,
    mode: 'onChange',
  });

  const [mutationState, mutate] = useBasicEnterPromoCodeMutation();

  const onSubmit = handleSubmit(async (data) => {
    if (data.value) {
      const res = await mutate(data);
      if (res.__typename === 'CheckPromoCodePayload' && res.useful) {
        dispatch('setPromoCode', data.value);
        onClose();
      }
    } else {
      dispatch('setPromoCode', null);
      onClose();
    }
  });

  const resultData = !mutationState.fetching && mutationState.data ? mutationState.data.result : null;

  return (
    <form className="bg-white py-8 text-center flex lg:h-full" onSubmit={onSubmit}>
      <div className="m-auto">
        <p className="font-bold text-xl">Промокод</p>
        <div className="px-20 my-6 xs:px-2">
          {formState.errors?.value && (
            <p className="text-xs text-left text-orange-50">{formState.errors.value.message}</p>
          )}
          <div className="mt-2 flex justify-between items-center">
            <input
              placeholder="Введите промокод"
              className="rounded-md w-full px-5 mr-3 flex items-center justify-center placeholder-gray-200 bg-gray-400 duration-100 focus:ring-1 focus:ring-cyan-50"
              style={{ height: '60px' }}
              {...register('value')}
            />
            <ProgressButton
              type="submit"
              isProgress={mutationState.fetching}
              styles="btn btn-orange flex justify-center items-center"
              disabled={!formState.isValid || !formState.isDirty}
            >
              <CheckIcon size={30} />
            </ProgressButton>
          </div>
        </div>
        {resultData && resultData.__typename === 'ErrorPayload' && <div>{resultData.message}</div>}
        {resultData && resultData.__typename === 'CheckPromoCodePayload' && (
          <div className="mt-6">
            <p className="text-orange-50">{resultData.message}</p>
            {resultData.useful && <div className="text-cyan-50">Промокод успешно применен!</div>}
          </div>
        )}
      </div>
    </form>
  );
};

gql`
  mutation EnterPromoCodeMutation($input: CheckPromoCodeInput!) {
    result: checkPromoCode(input: $input) {
      ... on ErrorPayload {
        message
      }

      ... on CheckPromoCodePayload {
        message
        useful
      }
    }
  }
`;
