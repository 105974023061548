import * as React from 'react';
import { graphql } from 'gatsby';
import { useCartItemQuantity } from 'hooks';
import { formatWeight } from 'utils';
import { ProductCounter_Product } from 'generated/graphql';
import MinusIcon from './icons/Minus';
import PlusIcon from './icons/Plus';
import { Price } from './Price';

type Props = {
  readonly product: ProductCounter_Product;
  readonly onIncrease?: () => void;
};

const renderLabel = (priceUnit: 'KG' | 'PCS' | 'PACK', quantity: number, weight: number | null | undefined) => {
  if (priceUnit === 'PCS' || priceUnit === 'PACK') {
    return `${quantity} ${priceUnit === 'PCS' ? 'шт' : 'уп'}`;
  }

  if (!weight) return null; // для TS. При priceUnit === 'KG' weight будет всегда number

  return formatWeight(quantity * weight);
};

export const ProductCounter = ({ product, onIncrease }: Props) => {
  const [quantity, dispatch] = useCartItemQuantity(product.id);

  const priceQuantity = quantity > 0 ? product.price * quantity : product.price;

  const decrease = (e: React.FormEvent<EventTarget>) => {
    e.stopPropagation();
    dispatch('decreaseCartItemQuantity', product);
  };

  const increase = (e: React.FormEvent<EventTarget>) => {
    e.stopPropagation();
    if (onIncrease) {
      onIncrease();
    }
    dispatch('increaseCartItemQuantity', product);
  };

  const increaseButtonDisabled = quantity >= product.counterMax;

  return (
    <div className="flex relative h-[48px] items-center justify-between px-[24px] mt-[16px] border border-orange-50 max-w-[214px] rounded xs:px-1">
      <button type="button" className="relative" onClick={decrease} aria-label="minus">
        <MinusIcon />
      </button>
      <div>
        <div className="flex flex-col justify-center items-center">
          <p className="flex flex-initial justify-center items-center mx-[19px] text-center text-md font-semibold text-orange-50 whitespace-nowrap">
            {increaseButtonDisabled ? 'max' : renderLabel(product.priceUnit, quantity, product.weight)}
          </p>
          <Price value={priceQuantity} className="text-sm font-normal text-[#666666]" />
        </div>
      </div>

      <button type="button" onClick={increase} disabled={increaseButtonDisabled} aria-label="plus">
        <PlusIcon />
      </button>
    </div>
  );
};

export const fragment = graphql`
  fragment ProductCounter_product on Product {
    id
    name
    weight
    price
    priceUnit
    counterStep
    counterInit
    counterMax
  }
`;
