import * as React from 'react';
import VkIcon from 'components/icons/VkWhite';
import TelegramIcon from 'components/icons/TelegramWhite';
import FacebookMessengerIcon from 'components/icons/FacebookMessenger';
import PhoneIcon from 'components/icons/PhoneWhite';

type Props = {
  readonly onClose: () => void;
};

const itemCn = 'flex items-center transition-opacity duration-300 ease-in-out hover:opacity-70 mb-4 lg:mb-5';

const itemIconWrapperCn = 'flex items-center justify-center w-8 h-8 rounded-full mr-3';

export const SupportModal = ({ onClose }: Props) => (
  <div className="modal modal-small">
    <div className="modal-title">Связаться с поддержкой</div>
    <div className="modal-content mb-6">
      <a
        href="https://www.messenger.com/t/familyfriendmoscow"
        target="_blank"
        rel="noopener noreferrer"
        className={itemCn}
      >
        <div className={itemIconWrapperCn} style={{ backgroundColor: '#00b2ff' }}>
          <FacebookMessengerIcon size={18} />
        </div>
        Facebook Messenger
      </a>
      <a href="https://vk.me/familyfriend_moscow" target="_blank" rel="noopener noreferrer" className={itemCn}>
        <div className={itemIconWrapperCn} style={{ backgroundColor: '#6383a8' }}>
          <VkIcon size={18} />
        </div>
        ВКонтакте
      </a>
      <a href="https://telegram.me/ffriend_bot" target="_blank" rel="noopener noreferrer" className={itemCn}>
        <div className={itemIconWrapperCn} style={{ backgroundColor: '#2ca5e0' }}>
          <TelegramIcon size={15} />
        </div>
        Telegram
      </a>
      <a href="tel:+74954199554" className="flex transition-opacity duration-300 ease-in-out hover:opacity-70">
        <div className={itemIconWrapperCn} style={{ backgroundColor: '#666666' }}>
          <PhoneIcon size={18} />
        </div>
        <div>
          +7 (495) 419-95-54
          <div className="text-gray-50" style={{ fontSize: 13 }}>
            Ежедневно с 7:00 до 21:00
          </div>
        </div>
      </a>
    </div>
    <button type="button" onClick={onClose} className="modal-button">
      Готово
    </button>
  </div>
);
