import * as React from 'react';

type Props = {
  readonly currentValue: number;
  readonly renderProcess: (time: number) => JSX.Element;
  readonly renderPending: () => JSX.Element;
};

export const Countdown = ({ currentValue, renderPending, renderProcess }: Props) => {
  const [value, setValue] = React.useState<number>(currentValue);

  React.useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  React.useEffect(() => {
    const t = setInterval(() => {
      setValue((s) => {
        if (s < 1) {
          clearInterval(s);
          return s;
        }

        return s - 1;
      });
    }, 1000);

    return () => clearInterval(t);
  }, []);

  return value === 0 ? renderPending() : renderProcess(value);
};
