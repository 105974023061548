import * as React from 'react';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { calcDiscount } from 'utils';
import { ProductDiscountBadge_Data } from 'generated/graphql';

type Props = {
  readonly data: ProductDiscountBadge_Data;
  readonly className?: string;
};

export const ProductDiscountBadge = ({ data, className }: Props) => {
  if (!data.oldPrice || data.oldPrice < 1) return null;

  const discount = calcDiscount(data.price, data.oldPrice);

  if (discount < 1) return null;

  return (
    <div
      className={cn(
        'flex items-center text-[#777] font-semibold bg-white px-[16px] py-[4px] rounded-tl-[4px] rounded-tr-[4px]',
        className,
      )}
    >
      -{discount}%
    </div>
  );
};

export const fragment = graphql`
  fragment ProductDiscountBadge_data on Product {
    price
    oldPrice
  }
`;
