import * as React from 'react';
import { graphql } from 'gatsby';
import cn from 'classnames';
import { useStoreon } from 'hooks';
import { ModalButton } from 'components/ModalLogic';
import GeoFlagGray from 'components/icons/GeoFlagGray';
import GeoFlagOrange from 'components/icons/GeoFlagOrange';
import EditIcon from 'components/icons/Edit';
import { Address } from 'components/Address';
import { UserActiveAddress_Data } from 'generated/graphql';

type Props = {
  readonly type: 'timeDelivery' | 'settings';
};

const buttonCn = 'flex items-center transition-colors duration-300 ease-in-out hover:text-orange-200';

const getlabel = (data: UserActiveAddress_Data) => {
  if (data.addresses.length === 0) {
    return 'Добавить адрес';
  }

  return data.activeAddress ? <Address data={data.activeAddress} className='line-clamp-1 max-w-[80vw] text-left' short /> : 'Выбрать адрес';
};

export const UserActiveAddress = ({ type }: Props) => {
  const { user } = useStoreon('user');

  if (!user) {
    return null;
  }

  if (type === 'timeDelivery') {
    return (
      <ModalButton options={{ type: 'changeActiveAddress' }} className={cn(buttonCn, 'text-sm')}>
        <GeoFlagGray size={20} className="mr-3" />
        <div className="-mb-1">{getlabel(user)}</div>
      </ModalButton>
    );
  }

  return (
    <ModalButton options={{ type: 'changeActiveAddress' }} className={cn(buttonCn, 'mb-14')}>
      <GeoFlagOrange size={30} className="mr-2" />
      {getlabel(user)}
      <div className="flex items-center justify-center w-7 h-7 bg-red-200 rounded-full ml-3">
        <EditIcon />
      </div>
    </ModalButton>
  );
};

export const fragments = graphql`
  fragment UserActiveAddress_data on User {
    activeAddress {
      id
      ...Address_data
    }
    addresses {
      id
    }
  }
`;
