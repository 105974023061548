import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ReCaptchaQuery } from 'generated/graphql';

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const useReCaptcha = (): { isReady: boolean; executeReCaptcha: () => Promise<string> } => {
  const [isReady, setIsReady] = React.useState<boolean>(false);

  const onLoad = () => {
    window.grecaptcha.ready(() => setIsReady(true));
  };

  const data = useStaticQuery<ReCaptchaQuery>(graphql`
    query ReCaptchaQuery {
      recaptchaSiteKey
    }
  `);

  React.useEffect(() => {
    if (data.recaptchaSiteKey) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${data.recaptchaSiteKey}`;
      script.async = true;
      script.defer = true;
      const el = document.body.appendChild(script);
      script.addEventListener('load', onLoad);
      return () => {
        document.body.removeChild(el);
        script.removeEventListener('load', onLoad);
      };
    }
    return () => null;
  }, [data.recaptchaSiteKey]);

  const executeReCaptcha = (): Promise<string> => {
    if (!isReady) {
      throw new Error('Captcha is not ready.');
    }
    return window.grecaptcha.execute(data.recaptchaSiteKey);
  };

  return { isReady, executeReCaptcha };
};
