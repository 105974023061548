import * as React from 'react';
import * as s from './ProgressButton.module.css';

type Props = {
  readonly isProgress?: boolean;
  readonly styles: string;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
  readonly onClick?: () => void;
  readonly type?: 'button' | 'submit' | 'reset' | undefined;
};

export const ProgressButton = ({ isProgress, styles, disabled, children, onClick, type }: Props) => {
  /* eslint-disable react/button-has-type */
  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isProgress) {
      setIsDisabled(true);
    }

    const t = setTimeout(() => {
      setIsDisabled(false);
    }, 500);

    return () => clearTimeout(t);
  }, [isProgress]);

  return (
    <button
      type={type}
      className={`${styles} ${s.btn} ${isProgress && s.btnClicked} 'disabled:bg-red-50'`}
      disabled={isDisabled || disabled}
      onClick={onClick}
    >
      <div className={`${isProgress && s.btnSpinClicked} ${s.btnSpin}`}>
        <div
          className="animate-spin ease-linear rounded-full border-2 border-t-2 border-white h-6 w-6"
          style={{ borderTopColor: '#363732' }}
        />
      </div>
      <div className={`${isProgress && s.btnChildClicked} ${s.btnChild}`}>{children}</div>
    </button>
  );
};
