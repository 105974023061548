import * as React from 'react';
import { Transition } from 'react-transition-group';
import ChatCloseIcon from './icons/ChatClose';
import ChatIcon from './icons/Chat';

type Props = {
  open: boolean;
  onClick: () => void;
  aboveBottomBar?: boolean;
};

const defaultStyle = {
  transition: `all ${200}ms ease-in-out`,
  transform: 'translate(-50%, -50%)',
};

const openIconStyles: any = {
  entering: { opacity: 0, transform: 'translate(-50%, -50%) rotate(45deg) scale(0.5)' },
  entered: { opacity: 0, transform: 'translate(-50%, -50%) rotate(45deg) scale(0.5)' },
  exiting: { opacity: 1, transform: 'translate(-50%, -50%) rotate(0) scale(1)' },
  exited: { opacity: 1, transform: 'translate(-50%, -50%) rotate(0) scale(1)' },
};

const closeIconStyles: any = {
  entering: { opacity: 1, transform: 'translate(-50%, -50%) rotate(0) scale(1)' },
  entered: { opacity: 1, transform: 'translate(-50%, -50%) rotate(0) scale(1)' },
  exiting: { opacity: 0, transform: 'translate(-50%, -50%) rotate(-45deg) scale(0.5)' },
  exited: { opacity: 0, transform: 'translate(-50%, -50%) rotate(-45deg) scale(0.5)' },
};

export const ChatButton = ({ open, onClick, aboveBottomBar }: Props) => {
  const nodeRef = React.useRef(null);
  return (
    <Transition nodeRef={nodeRef} timeout={200} in={open}>
      {(state) => (
        <button
          ref={nodeRef}
          type="button"
          onClick={onClick}
          style={{ boxShadow: `rgba(0, 0, 0, 0.16) 0px 5px 40px` }}
          className={`flex items-center justify-center w-16 h-16 bg-[#3047ec] fixed z-10 right-5 bottom-5 ${
            aboveBottomBar ? 'lg:bottom-16' : ''
          } rounded-full`}
        >
          <span className="relative w-full h-full">
            <ChatCloseIcon
              style={{ ...defaultStyle, ...closeIconStyles[state] }}
              className="absolute top-1/2 left-1/2"
              size={20}
            />
            <ChatIcon style={{ ...defaultStyle, ...openIconStyles[state] }} className="absolute top-1/2 left-1/2" />
          </span>
        </button>
      )}
    </Transition>
  );
};
