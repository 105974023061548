import * as React from 'react';
import cn from 'classnames';
import Star from 'components/icons/Star';
import StarActive from 'components/icons/StarActive';

type Props = {
  readonly rating: number;
  readonly className?: string;
  readonly onClick?: (value: number) => void;
  readonly iconSize?: number;
};

export const Rating = ({ rating, className, onClick, iconSize }: Props) => {
  const r = Math.round(rating);
  const arr = [...Array(5).keys()];

  return (
    <span className={cn(className, 'flex')}>
      {arr.map((n) => {
        const icon = r > n ? <StarActive size={iconSize} /> : <Star size={iconSize} />;
        if (onClick) {
          return (
            <button type="button" onClick={() => onClick(n + 1)} key={n} className="mr-1 last:mr-0">
              {icon}
            </button>
          );
        }
        return (
          <span key={n} className="mr-1 last:mr-0">
            {icon}
          </span>
        );
      })}
    </span>
  );
};
