import { useStoreon } from 'hooks';
import { Link } from 'gatsby';
import * as React from 'react';
import { Transition, TransitionStatus } from 'react-transition-group';
import { nonNullable } from 'utils';
import { SignInButton } from './SignInButton';
import CloseIcon from './icons/Close';
import LogoImg from './icons/logo.svg';
import { AuthRequiredLink } from './AuthRequiredLink';

const defaultStyle: React.CSSProperties = {
  transition: `all 200ms ease-in-out`,
  top: 0,
  transform: 'translateX(30px)',
  opacity: 0,
};

const transitionStyles: { [key in TransitionStatus]: React.CSSProperties } = {
  entering: { opacity: 1, transform: 'translateX(0)' },
  entered: { opacity: 1, transform: 'translateX(0)' },
  exiting: {},
  exited: {},
  unmounted: {},
};

const Links = () => {
  const { user, dispatch } = useStoreon('user');

  const handleQuit = () => {
    // eslint-disable-next-line
    if (confirm('Вы уверены, что хотите выйти из аккаунта?')) {
      // eslint-disable-next-line no-console
      console.log('reset token: after handle quit');
      dispatch('setToken', null);
    }
  };

  const getMyAddressesLabel = () => {
    if (user?.addresses.length === 0) {
      return 'Добавить адрес';
    }

    return user?.activeAddress ? 'Мои адреса' : 'Выбрать адрес';
  };

  const links = [
    {
      label: 'Каталог',
      to: '/catalog',
    },
    {
      label: 'Любимое',
      to: '/my/favorites',
      authRequired: true,
      badge: user?.favoriteProductIds.length || null,
    },
    user && {
      label: 'Купленные продукты',
      to: '/my/bought',
      AuthRequiredLink: true,
    },
    user && {
      label: 'Мои заказы',
      to: '/my/orders',
    },
    user && {
      label: getMyAddressesLabel(),
      to: '/my/addresses',
    },
    user && user.depositBalance > 0 && {
      label: 'Депозит',
      to: '/my/deposit',
    } || null,
    {
      label: 'О нас',
      to: '/about',
    },
    {
      label: 'FAQ',
      to: '/faq',
    },
    {
      label: 'О проекте',
      to: '/about-app',
    },
    {
      label: 'Партнерам',
      to: '/partners',
    },
    {
      label: 'Курьеры',
      to: '/delivery/form',
    },
    {
      label: 'Возврат',
      to: '/returns',
    },
    {
      label: 'Оплата',
      to: '/payment',
    },
    user && {
      label: 'Настройки',
      to: '/my/settings',
    },
    user && {
      label: 'Выйти',
      onclick: handleQuit,
    },
  ];

  const cn =
    'w-full h-14 flex items-center justify-between px-2 hover:text-red-50 first:border-t-[1px] border-b-[1px] border-[#ebebeb]';
  const badgeCn = 'text-black text-sm bg-[#dbdbdb] text-white py-1 px-2.5 rounded-xl';

  return (
    <div className="flex flex-col items-center mb-4">
      {links.filter(nonNullable).map((i) => {
        if (i.onclick) {
          return (
            <button type="button" key={i.label} onClick={i.onclick} className={cn}>
              {i.label}
            </button>
          );
        }

        if (i.authRequired) {
          return (
            <AuthRequiredLink key={i.to} to={i.to} classNames={cn} activeClassName="text-red-50">
              {i.label}
              {i.badge && <span className={badgeCn}>{i.badge}</span>}
            </AuthRequiredLink>
          );
        }

        return (
          <Link key={i.to} to={i.to} className={cn} activeClassName="text-red-50">
            {i.label}
            {i.badge && <span className={badgeCn}>{i.badge}</span>}
          </Link>
        );
      })}
    </div>
  );
};

type WrapperProps = {
  opened: boolean;
};

const MenuWrapper: React.FC<WrapperProps> = ({ children, opened }) => (
  <Transition timeout={200} in={opened} onEnter={(node: any) => node.offsetHeight} unmountOnExit mountOnEnter>
    {(state) => (
      <div
        style={{ ...defaultStyle, ...transitionStyles[state] }}
        className="fixed bg-white top-0 left-0 bottom-0 right-0 px-2 pt-3 overflow-y-auto"
      >
        {children}
      </div>
    )}
  </Transition>
);

type Props = {
  children: () => React.ReactNode;
  className: string;
};

export const MobileMenu = ({ className, children }: Props) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const { user } = useStoreon('user');

  React.useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-y-hidden');
    }
    return () => document.body.classList.remove('overflow-y-hidden');
  }, [open]);

  const handleOpen = () => setOpen((o) => !o);

  return (
    <>
      <button type="button" onClick={handleOpen} className={className}>
        {children()}
      </button>
      <MenuWrapper opened={open}>
        <div className="relative flex flex-col items-center justify-center mb-5">
          <button
            type="button"
            className="transform rotate-180 h-12 w-12 flex flex-col justify-center items-center absolute top-0 right-1"
            onClick={handleOpen}
          >
            <CloseIcon size={28} />
          </button>
          <Link to="/" className="flex-shrink-0">
            <img src={LogoImg} alt="" className="w-[100px]" />
          </Link>
          {!user && (
            <>
              <div className="text-center text-sm max-w-[300px] mt-2">Войдите, чтобы совершать заказы</div>
              <SignInButton classNames="flex justify-center items-center mt-[12px] px-[24px] py-[12px] rounded-[4px] text-md text-white bg-orange-50 h-12">
                Войти
              </SignInButton>
            </>
          )}
        </div>
        <div className="w-full">
          <Links />
        </div>
      </MenuWrapper>
    </>
  );
};
