import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { CreateAddressInput } from 'generated/graphql';
import cn from 'classnames';
import { ProgressButton } from './ProgressButton';
import { TextField } from './TextField';

type InputItem = {
  name: keyof Omit<CreateAddressInput, 'location'>;
  label: string;
  blurOnSubmit?: boolean;
  placeholder?: string;
  returnKeyType?: 'next' | 'send';
  style?: object;
  required?: boolean;
};

const inputs: Array<Array<InputItem>> = [
  [
    {
      name: 'city',
      label: 'Город',
      placeholder: 'Ваш город',
      required: true,
    },
  ],
  [
    {
      name: 'street',
      label: 'Улица',
      required: true,
    },
  ],
  [
    {
      name: 'house',
      label: 'Дом',
    },
    {
      name: 'building',
      label: 'Строение',
    },
    {
      name: 'entrance',
      label: 'Подъезд',
    },
  ],
  [
    {
      name: 'appartment',
      label: 'Квартира',
    },
    {
      name: 'buildingFloor',
      label: 'Этаж',
    },
    {
      name: 'intercomCode',
      label: 'Код домофона',
    },
  ],
  [
    {
      name: 'comment',
      label: 'Комментарий',
    },
  ],
];

type AddressViewProps = {
  className?: string;
  hideTitle?: boolean;
  loading?: boolean;
  control: Control<CreateAddressInput>;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  title?: string;
};

export const CreateAddressView = ({ className, control, hideTitle, onSubmit, loading, title }: AddressViewProps) => (
  <form
    className={cn('bg-white p-4 min-h-full flex flex-col items-center justify-center', className)}
    onSubmit={onSubmit}
  >
    {!hideTitle && <div className="text-center text-xl font-bold tracking-wide mb-5">{title || 'Добавить адрес'}</div>}
    {inputs.map((row, rowIndex) => (
      <div key={rowIndex /* eslint-disable-line react/no-array-index-key */} className="flex w-full mb-1">
        {row.map((i) => (
          <Controller
            key={i.name}
            control={control}
            render={({ field: { onChange: onChangeText, onBlur, value } }) => (
              <TextField
                label={i.label}
                name={i.name}
                onBlur={onBlur}
                onChangeText={onChangeText}
                value={value || undefined}
                required={i.required || false}
              />
            )}
            {...i}
          />
        ))}
      </div>
    ))}
    <ProgressButton
      type="submit"
      isProgress={loading}
      styles="btn btn-orange mt-5 mx-auto items-center justify-center flex-col overflow-hidden"
    >
      Сохранить
    </ProgressButton>
  </form>
);
