import * as React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  readonly title?: string;
};

export const HeadMetaData = ({ title }: Props) => (
  <Helmet
    htmlAttributes={{
      lang: 'ru',
    }}
  >
    <meta charSet="utf-8" />
    <title>{title || 'Быстрая доставка свежих и вкусных продуктов'}</title>
    <meta
      name="keywords"
      content="family friend скачать приложение, family friend скачать, familyfriend скачать, familyfriend скачать приложение, familyfriend, family friend, food, доставка, быстрая доставка, доставка еды, онлайн, online, оплата картой, продукты, еда, деликатесы, окей, покупки, сыр, соки, delivery, скидки, акции, магазин, club, family, friend, деливери, заказ"
    />
    <meta
      name="description"
      content="Приложение Family Friend – это доставка свежих и вкусных продуктов. Наши курьеры привезут вам покупки в течение 2 часов, а сборщики выберут самые качественные продукты."
    />
  </Helmet>
);
