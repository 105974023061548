import * as React from 'react';
import classNames from 'classnames';
import { TextField } from 'components/TextField';
import { gql } from 'urql';
import { useStoreon } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import { ProgressButton } from 'components/ProgressButton';
import { useUpdatePasswordMutation } from 'generated/graphql';

type WrapperProps = {
  className?: string;
  title: string;
};

const Wrapper: React.FC<WrapperProps> = ({ children, className, title }) => (
  <div className={classNames('relative bg-white p-7 pb-10 rounded lg:w-full lg:min-h-full flex', className)}>
    <div className="m-auto w-[600px]">
      <div className="text-center text-xl font-bold tracking-wide mb-5">{title}</div>
      {children}
    </div>
  </div>
);

export const UpdatePassword = ({ onClose, title = 'Сменить пароль' }: { onClose: () => void; title?: string }) => {
  const [error, setError] = React.useState<Error | null>(null);

  const [res, updateMutate] = useUpdatePasswordMutation();
  const { user } = useStoreon('user');
  const { control, handleSubmit, formState } = useForm<{ oldPassword: string; newPassword: string }>();
  const { errors } = formState;

  const onSubmit = handleSubmit(async (values) => {
    setError(null);
    try {
      const updateRes = await updateMutate({ input: values });
      if (updateRes.error) throw new Error(updateRes.error.message);
      if (updateRes.data?.updatePassword.__typename === 'ErrorPayload') {
        throw new Error(updateRes.data.updatePassword.message);
      }
      if (updateRes.data?.updatePassword.__typename === 'UpdatePasswordPayload') {
        onClose();
      }
    } catch (err) {
      setError(err);
    }
  });

  if (!user) return <div>Авторизуйтесь</div>;

  const errorView = error && <p className="text-orange-50 text-sm text-center">{error.message}</p>;

  return (
    <Wrapper title={title}>
      <form onSubmit={onSubmit}>
        {user.withPassword && (
          <Controller
            control={control}
            name="oldPassword"
            rules={{
              required: 'Введите старый пароль',
            }}
            render={({ field }) => (
              <TextField
                label="Старый пароль"
                error={errors.oldPassword?.message}
                value={field.value}
                onChangeText={field.onChange}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="newPassword"
          rules={{
            required: 'Введите новый пароль',
            minLength: { value: 8, message: 'Пароль должен содержать минимум 8 символов' },
          }}
          render={({ field }) => (
            <TextField
              label="Новый пароль"
              error={errors.newPassword?.message}
              value={field.value}
              onChangeText={field.onChange}
            />
          )}
        />
        {errorView}
        <ProgressButton
          disabled={false}
          type="submit"
          isProgress={res.fetching}
          styles="btn-orange h-14 px-20 mt-4 flex flex-initial items-center text-base text-center font-medium text-white rounded-md uppercase tracking-wide mx-auto"
        >
          Далее
        </ProgressButton>
      </form>
    </Wrapper>
  );
};

gql`
  fragment UpdatePassword_data on User {
    id
    withPassword
  }

  mutation UpdatePasswordMutation($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      ... on UpdatePasswordPayload {
        viewer {
          id
          user {
            id
            ...UpdatePassword_data
          }
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
