import * as React from 'react';
import { graphql } from 'utils';
import { Address_Data } from 'generated/graphql';

type Part = {
  prefix: string;
  key: keyof Address_Data;
};

type Props = {
  readonly data: Address_Data;
  readonly short?: boolean;
  readonly className?: string;
};

const shortParts: Part[] = [
  { prefix: 'ул. ', key: 'street' },
  { prefix: 'д. ', key: 'house' },
];

const allParts: Part[] = [
  ...shortParts,
  { prefix: 'стр. ', key: 'building' },
  { prefix: 'подъезд ', key: 'entrance' },
  { prefix: 'этаж ', key: 'buildingFloor' },
  { prefix: 'кв. ', key: 'appartment' },
  { prefix: '', key: 'comment' },
];

export const Address = ({ data, short, className }: Props) => {
  const parts = short ? shortParts : allParts;

  return (
    <div className={className}>
      {data.city && data.city !== '' && <span>{data.city}</span>}
      {parts.map((p) => {
        if (!data[p.key] || data[p.key] === '') {
          return null;
        }

        return <span key={p.key.toString()}>{`, ${p.prefix}${data[p.key]}`}</span>;
      })}
    </div>
  );
};

export const fragments = graphql`
  fragment Address_data on Address {
    id
    city
    street
    house
    comment
    entrance
    building
    buildingFloor
    appartment
    intercomCode
  }
`;
