/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import cn from 'classnames';
import { ProductCard_Product } from 'generated/graphql';
import { useCartItemQuantity } from 'hooks';
import { formatQuantity } from 'utils';
import CartWhiteIcon from 'components/icons/CartWhite';
import FavoriteHearthActiveIcon from 'components/icons/FavoriteHearthActive';
import FavoriteHearthIcon from 'components/icons/FavoriteHearth';
import { animateProductToCart } from 'utils/animateProductToCart';
import { ProductToggleFavorite } from './ProductToggleFavorite';
import { ProductCounter } from './ProductCounter';
import { Price } from './Price';
import { ProductBadge } from './ProductBadge';
import { ProductDiscountBadge } from './ProductDiscountBadge';
import * as s from './ProductCard.module.css';
import { Image } from './Image';

// hight/width - 1/1
const SquareDiv: React.FC<{ className?: string }> = ({ children, className }) => (
  <div className={cn(className, 'relative overflow-hidden')}>
    <div style={{ paddingTop: '100%' }} />
    <div className="absolute top-0 left-0 right-0 bottom-0 ">{children}</div>
  </div>
);

type ToCardProps = {
  className?: string;
  style?: React.CSSProperties;
  skeleton?: boolean;
  onClick?: () => void;
};

type RemoveFromCartButtonProps = {
  readonly onClick: () => void;
};

const RemoveFromCartButton = ({ onClick }: RemoveFromCartButtonProps) => (
  <button
    type="button"
    className="flex justify-center items-center mt-[16px] px-[24px] py-[12px] rounded-[4px] text-md text-white bg-orange-50 h-12"
    onClick={onClick}
  >
    <span>Убрать из корзины</span>
  </button>
);

const ToCartButton = ({ className, style, skeleton, onClick }: ToCardProps) => (
  <button
    type="button"
    style={style}
    className={cn(
      className,
      'flex justify-center items-center mt-[16px] px-[24px] py-[12px] rounded-[4px] text-md text-white bg-orange-50 h-12',
    )}
    onClick={onClick}
  >
    <span>В корзину</span>
    <span className="block ml-[11px] xs:hidden">{skeleton ? <span className="block w-6" /> : <CartWhiteIcon />}</span>
  </button>
);

// all texts must be transparent, they are used for correct width/height
export const ProductCardSkeleton = ({ containerStyle }: { containerStyle?: string }) => (
  <div className={cn(containerStyle, 'w-full max-w-[275.5px] skeleton-loader-parent select-none')}>
    <div className="skeleton-loader-overlay" />

    <SquareDiv className="mb-2 overflow-hidden rounded-[12px] bg-gray-400" />
    <div className="min-h-[210px] xs:min-h-[186px] flex flex-col justify-between">
      <div>
        <span
          className={`${s.productName} mb-2 overflow-ellipsis overflow-hidden max-h-12 lg:max-h-10 leading-[1.3] text-[17px] xs:text-sm text-transparent bg-gray-400`}
        >
          Lorem ipsum dolor sit amet consectetur
        </span>
      </div>
      <div className="">
        <Price value={100} className="text-[22px] xs:text-lg text-transparent bg-gray-400 inline" />
        <ToCartButton
          skeleton
          style={{ backgroundColor: 'rgba(246, 246, 246)' }}
          className="text-transparent cursor-default"
        />
      </div>
    </div>
  </div>
);

type CardProps = {
  data: ProductCard_Product;
  currentCategoryId?: number;
  withoutMoveAnim?: boolean;
  className?: string;
  hideProductBadge?: boolean;
};

export const ProductCard = ({ data, currentCategoryId, withoutMoveAnim, className, hideProductBadge }: CardProps) => {
  const [quantity, dispatch] = useCartItemQuantity(data.id);
  const ref = React.useRef<HTMLDivElement>(null);
  const inCart = quantity > 0;
  const increaseDisabled = quantity >= data.counterMax || data.counterMax === 0;

  const animateToCart = animateProductToCart(ref);
  const animate = () => {
    if (animateToCart && !withoutMoveAnim) {
      animateToCart();
    }
  };

  const increase = () => {
    if (increaseDisabled) return; // чтобы нельзя было добавлять при клике на контент карточки
    animate();
    dispatch('increaseCartItemQuantity', data);
  };

  const removeCartItem = () => dispatch('removeCartItem', data.id);

  return (
    <div ref={ref} className={cn(className, 'w-full max-w-[275.5px]')}>
      <SquareDiv className="mb-2 overflow-hidden rounded-[12px] bg-gray-400">
        <Link to={data.pageUrl} className="product__link z-50 block overflow-hidden">
          <Image className="product__link-img" src={data.imageUrlNormal} srcSet={data.imageUrlRetina} alt={data.name} />
          <div className="absolute left-0 top-0 w-full h-full product__link-placeholder flex justify-center items-center text-white font-semibold uppercase text-sm">
            Подробнее
          </div>
          <ProductDiscountBadge data={data} className="absolute bottom-0 right-3" />
        </Link>
        {hideProductBadge || <ProductBadge data={data} currentCategoryId={currentCategoryId} />}
        <ProductToggleFavorite
          className="absolute top-3 right-3"
          id={data.id}
          data={{
            name: data.name,
            price: data.price,
          }}
          render={(favorite) => <span>{favorite ? <FavoriteHearthActiveIcon /> : <FavoriteHearthIcon />}</span>}
        />
      </SquareDiv>
      <div className="min-h-[210px] xs:min-h-[186px] flex flex-col justify-between">
        <div>
          <p
            className={`${s.productName} mb-2 overflow-ellipsis overflow-hidden max-h-12 lg:max-h-10 leading-[1.3] text-[17px] xs:text-sm line-clamp-2`}
          >
            {data.name}
          </p>
          <p className="text-md text-gray-200 xs:text-sm">{formatQuantity(data.priceUnit, data.weight, data.volume)}</p>
        </div>
        <div>
          {data.counterMax > 0 ? (
            <>
              {data.oldPrice && data.oldPrice > 0 ? (
                <Price value={data.oldPrice} className="text-[#666666] font-normal text-md line-through xs:text-sm" />
              ) : null}
              <Price value={data.price} className="text-[22px] font-semibold text-orange-50 xs:text-lg" />
              {inCart ? <ProductCounter product={data} onIncrease={animate} /> : <ToCartButton onClick={increase} />}
            </>
          ) : (
            <>
              <div className="text-red-50 text-sm py-3">Нет в наличии</div>
              {inCart && <RemoveFromCartButton onClick={removeCartItem} />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment ProductCard_product on Product {
    id
    pageUrl
    name
    imageUrlNormal: mainImageUrl(size: 200)
    imageUrlRetina: mainImageUrl(size: 400)
    price
    counterMax
    oldPrice
    volume
    hitLeft
    ...ProductCounter_product
    ...ProductBadge_data
    ...ProductDiscountBadge_data
  }
`;
