import * as React from 'react';
import { OrderPaymentMethod } from 'generated/graphql';
import { useStoreon } from 'hooks';
import { DontUseAutoPayment } from '../components/DontUseAutoPayment';

type PaymentMethodType = {
  name: OrderPaymentMethod;
  label: string;
};

export const paymentMethods: PaymentMethodType[] = [
  { name: OrderPaymentMethod.Online, label: 'Онлайн' },
  { name: OrderPaymentMethod.ByCash, label: 'Наличными курьеру' },
  { name: OrderPaymentMethod.ByCard, label: 'Картой курьеру' },
  { name: OrderPaymentMethod.Deposit, label: 'Депозит' },
];

export const ChangePaymentMethod = () => {
  const { dispatch, user, paymentMethod } = useStoreon('user', 'paymentMethod');

  const onChange = (value: OrderPaymentMethod) => {
    dispatch('setPaymentMethod', value);
  };

  const method = paymentMethod || user?.lastOrder?.paymentMethod || OrderPaymentMethod.Online;

  if (!user) return null;

  return (
    <div
      style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
      className="bg-white p-10 flex flex-col items-center justify-center  min-h-full rounded  max-w-full w-[1000px] modal modal-small lg:items-center"
    >
      <p className="font-bold text-lg mb-3">Выберите способ оплаты:</p>
      <div className="flex flex-col w-full">
        {paymentMethods.map(({ name, label }) => {
          const className = name === method ? 'bg-orange-50 text-white' : '';
          return (
            <button type="button" key={name} onClick={() => onChange(name)} className="flex items-center mr-5 mb-1">
              <span className="w-4 h-4 rounded-full p-2 flex-shrink-0 flex items-center justify-center border border-red-50 mr-3">
                <span className={`w-2.5 h-2.5 flex-shrink-0 rounded-full block ${className}`} />
              </span>
              <div>{label}</div>
            </button>
          );
        })}
      </div>
      {method === OrderPaymentMethod.Online && <DontUseAutoPayment user={user} />}
    </div>
  );
};
