import * as React from 'react';
import { graphql } from 'gatsby';
import { getNumberArray } from 'utils';
import { ProductCardList_Data } from 'generated/graphql';
import { ProductCard, ProductCardSkeleton } from './ProductCard';

type Props = {
  readonly products: readonly ProductCardList_Data[];
  readonly name?: string;
  readonly currentCategoryId?: number;
  readonly hideProductBadge?: boolean;
};

export const ProductListWrapper: React.FC<{ name?: string }> = ({ children, name }) => (
  <>
    {name && <h1 className="py-8 text-2xl font-semibold tracking-wider  lg:text-xl">{name}</h1>}
    <div className="grid grid-cols-5 gap-5 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 sm:gap-x-2 xs:justify-items-center w-full">
      {children}
    </div>
  </>
);

export const ProductCardListSkeleton = ({ count = 10, name }: { count?: number; name?: string }) => (
  <ProductListWrapper name={name}>
    {getNumberArray(count).map((i) => (
      <ProductCardSkeleton key={i} />
    ))}
  </ProductListWrapper>
);

export const ProductCardList = ({ products, name, currentCategoryId, hideProductBadge }: Props) => (
  <ProductListWrapper name={name}>
    {products.map((i) => (
      <ProductCard key={i.id} data={i} currentCategoryId={currentCategoryId} hideProductBadge={hideProductBadge} />
    ))}
  </ProductListWrapper>
);

export const query = graphql`
  fragment ProductCardList_data on Product {
    id
    ...ProductCard_product
  }
`;
