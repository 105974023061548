import * as React from 'react';
import { useAddToCartQuery } from 'generated/graphql';
import { gql } from 'urql';
import { ContentTitle } from 'components/ContentTitle';
import { CartState } from 'store';
import { useStoreon } from 'hooks';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { ProductCardList, ProductCardListSkeleton } from 'components/ProductCardList';

type Props = {
  cartItems: CartState;
  onClose: () => void;
};

type WrapperProps = { title?: string; onClose: () => void; actions?: React.ReactNode };

const Wrapper: React.FC<WrapperProps> = ({ children, title = 'Добавить в корзину', onClose, actions }) => (
  <div className="bg-white w-[1010px] px-5 flex flex-col justify-center rounded-lg lg:w-full lg:min-h-full xs:px-2">
    <ContentTitle title={title} className="mt-5 mb-4" />
    <div className="py-2">{children}</div>
    <div className="flex py-5 xs:flex-col">
      {actions}
      <button
        style={{ fontWeight: 'normal', fontSize: 16, padding: '8px 12px', color: 'white', borderRadius: '8px' }}
        type="button"
        onClick={onClose}
        className="bg-orange-50 ml-auto xs:ml-0 xs:mt-2.5"
      >
        Закрыть
      </button>
    </div>
  </div>
);

export const AddToCart = ({ cartItems, onClose }: Props) => {
  const [res] = useAddToCartQuery({ variables: { ids: cartItems.map(({ productId }) => productId) } });
  const { pathname } = useLocation();
  const { dispatch } = useStoreon();

  if (res.error) {
    return (
      <Wrapper title="Что-то пошло не так" onClose={onClose}>
        {res.error.message}
      </Wrapper>
    );
  }
  if (res.fetching) {
    return (
      <Wrapper onClose={onClose}>
        <ProductCardListSkeleton count={cartItems.length || 5} />
      </Wrapper>
    );
  }

  const list = res.data?.products || [];

  const handleReplaceAll = () => {
    dispatch(
      'replaceAllCart',
      cartItems.filter((i) => list.some((p) => p.id === i.productId)),
    );
    navigate(pathname);
    onClose();
  };
  const handleAddAll = () => {
    dispatch(
      'addCartItems',
      cartItems.filter((i) => list.some((p) => p.id === i.productId)),
    );
    navigate(pathname);
    onClose();
  };

  return (
    <Wrapper
      onClose={onClose}
      actions={
        list.length ? (
          <div className="flex xs:flex-col">
            <button
              style={{ fontWeight: 'normal', fontSize: 16, padding: '8px 12px', color: 'white', borderRadius: '8px' }}
              type="button"
              onClick={handleReplaceAll}
              className="bg-orange-50"
            >
              Заменить
            </button>
            <button
              type="button"
              onClick={handleAddAll}
              className="bg-orange-50 py-2 px-3 rounded-lg ml-2.5 text-white xs:ml-0 xs:mt-2.5"
            >
              Добавить к текущей
            </button>
          </div>
        ) : null
      }
    >
      {list.length ? <ProductCardList products={list} /> : <div>Ничего не найдено</div>}
    </Wrapper>
  );
};

gql`
  query addToCartQuery($ids: [Int!]) {
    products(ids: $ids) {
      id
      name
      oldPrice
      price
      counterMax
      pageUrl
      imageUrlNormal: mainImageUrl(size: 200)
      imageUrlRetina: mainImageUrl(size: 400)
      volume
      ...ProductCard_product
    }
  }
`;
