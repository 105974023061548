import * as React from 'react';
import { gql } from '@urql/core';
import cn from 'classnames';
import { useFavorite } from 'hooks';
import { captureException } from 'utils';
import { addToFavorite } from 'analytics';
import { useUserResolver } from './Auth';
import { useBasicProductToggleFavoriteMutation } from '../generated/graphql';

type Props = {
  readonly id: number;
  readonly data: {
    name: string;
    price: number;
  };
  readonly render: (favorite?: boolean | null) => React.ReactNode;
  readonly className?: string;
};

export const ProductToggleFavorite = ({ id, data, render, className }: Props) => {
  const favorite = useFavorite(id);
  const [, mutate] = useBasicProductToggleFavoriteMutation();
  const [getUser, signInModal] = useUserResolver();

  const likeHandler = async () => {
    addToFavorite({ id, ...data });
    const user = await getUser();
    if (!user) {
      return;
    }

    try {
      await mutate({ id });
    } catch (err) {
      captureException(err);
    }
  };

  return (
    <>
      {signInModal}
      <button type="button" className={cn(className, 'block')} onClick={likeHandler}>
        {render(favorite)}
      </button>
    </>
  );
};

gql`
  mutation ProductToggleFavoriteMutation($input: ToggleFavoriteProductInput!) {
    result: toggleFavoriteProduct(input: $input) {
      ... on ToggleFavoriteProductPayload {
        user {
          id
          favoriteProductIds
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
