import * as React from 'react';
import { useStoreon } from 'hooks';
import { formatDay, groupBy, formatNumberTime } from '../utils';

export const DeliveryTimePicker = () => {
  const { dispatch, deliveryIntervals: intervals, deliveryAt } = useStoreon('deliveryIntervals', 'deliveryAt');
  const dayRef = React.useRef(null);
  const timeRef = React.useRef(null);

  const selectedDay = formatDay(deliveryAt || intervals[0].value);
  const dayGroups = groupBy(intervals, (i) => formatDay(i.value));
  const dayIntervals = dayGroups[selectedDay] || dayGroups[Object.keys(dayGroups)[0]];

  if (!dayIntervals) {
    return <div>что-то пошло не так</div>;
  }

  const onClose = () => {
    dispatch('setModal', null);
  };

  return (
    <div className="bg-white py-8 text-center lg:min-h-full flex">
      <div className="w-full m-auto">
        <p className="font-bold text-xl px-2">Выберите день и время доставки</p>
        <div className="px-20 my-6 lg:px-5">
          <div className="mt-2 max-w-[700px] lg:max-w-[100%]">
            <div className="flex flex-wrap xs:items-center xs:justify-center">
              {Object.keys(dayGroups).map((k) => {
                const active = selectedDay === k;

                const onClick = () => {
                  const firstInterval = intervals.find((i) => k === formatDay(i.value));

                  if (firstInterval) {
                    dispatch('setDeliveryAt', firstInterval?.value);
                  }
                };

                return (
                  <div
                    key={k}
                    role="button"
                    tabIndex={0}
                    ref={dayRef}
                    className={`${
                      active && 'bg-orange-50 text-white'
                    } bg-gray-400 rounded-md mx-1.5 mt-2 px-3 py-2 font-medium cursor-pointer hover:bg-opacity-80`}
                    onClick={onClick}
                    onKeyDown={onClick}
                  >
                    {k}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-wrap mt-2 mb-4 xs:items-center xs:justify-center">
              {dayIntervals.map((i) => {
                const active = i.value === deliveryAt;

                const onClick = () => {
                  dispatch('setDeliveryAt', i.value);
                };

                return (
                  <div
                    key={i.id}
                    role="button"
                    tabIndex={0}
                    ref={timeRef}
                    className={`${
                      active && 'bg-orange-50 text-white'
                    } flex bg-gray-400 rounded-md mx-1.5 mt-2 px-3 py-2 font-medium cursor-pointer hover:bg-opacity-80`}
                    onClick={onClick}
                    onKeyDown={onClick}
                  >
                    <span>{formatNumberTime(i.from)}</span>
                    <span className="mx-1">-</span>
                    <span>{formatNumberTime(i.to)}</span>
                  </div>
                );
              })}
            </div>
            <button type="submit" onClick={onClose} className="btn btn-orange ml-1.5">
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
