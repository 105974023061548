import * as React from 'react';
import { createPortal } from 'react-dom';
import { graphql, useStaticQuery } from 'gatsby';
import { useStoreon } from 'hooks';
import { AppDataViewer, AuthQuery, MainSignInMethod } from 'generated/graphql';
import { Modal } from './Modal';
import { AuthEnterPhone } from './AuthEnterPhone';
import { AuthEnterCode } from './AuthEnterCode';
import { AuthByPassword } from './AuthByPassword';

// создается через gatsby-plugin-portal
const root = typeof document !== 'undefined' ? document.getElementById('modal-root') : null;

type User = AppDataViewer['user'];

type Props = {
  onClose: (v: User) => void;
};

export const SignInModal = (props: Props) => {
  const data = useStaticQuery<AuthQuery>(graphql`
    query AuthQuery {
      viewer {
        features {
          mainSignInMethod
        }
      }
    }
  `);

  const defaultSignInMethod =
    data.viewer.features.mainSignInMethod === MainSignInMethod.ByPassword ? 'enterPassword' : 'enterPhone';

  const container = React.useRef<HTMLDivElement | null>();

  React.useEffect(
    () => () => {
      if (container.current && root) {
        root.removeChild(container.current);
      }
    },
    [],
  );

  const { dispatch } = useStoreon();
  const [phone, setPhone] = React.useState('');
  const [step, setStep] = React.useState<'enterPhone' | 'enterCode' | 'enterPassword'>(defaultSignInMethod);
  const [codeLength, setCodeLength] = React.useState(5);
  const [closing, setClosing] = React.useState<boolean>(false);

  const onCloseProp = props.onClose;

  const handleClose = React.useCallback(() => {
    onCloseProp(null);
  }, [onCloseProp]);

  const onClose = () => setClosing(true);

  React.useEffect(() => {
    const handleCloseAsync = () => {
      if (closing) {
        handleClose();
      }
    };

    const t = setTimeout(handleCloseAsync, 200);
    return () => clearInterval(t);
  }, [closing, handleClose]);

  if (!container.current && root) {
    const el = document.createElement('div');
    root.appendChild(el);
    container.current = el;
  }

  let widget: React.ReactNode = null;

  if (step === 'enterPassword') {
    widget = (
      <AuthByPassword
        signInByCode={() => setStep('enterPhone')}
        onSuccess={(token, viewer) => {
          props.onClose(viewer.user);
          dispatch('setToken', token);
        }}
      />
    );
  }

  if (step === 'enterPhone') {
    widget = (
      <AuthEnterPhone
        onSuccess={(successPhone, cl) => {
          setStep('enterCode');
          setPhone(successPhone);
          if (cl) {
            setCodeLength(cl);
          }
        }}
        signInByPassword={() => setStep('enterPassword')}
      />
    );
  }

  if (step === 'enterCode') {
    widget = (
      <AuthEnterCode
        phone={phone}
        codeLength={codeLength}
        onSuccess={(token, viewer) => {
          props.onClose(viewer.user);
          dispatch('setToken', token);
        }}
        onCancel={() => setStep('enterPhone')}
      />
    );
  }

  const tree = (
    <Modal closing={closing} onClose={onClose}>
      {widget}
    </Modal>
  );

  return createPortal(tree, container.current as HTMLDivElement);
};

export const useUserResolver = (): [() => Promise<User>, React.ReactNode, User] => {
  const { appData } = useStoreon('appData');
  const { viewer } = appData;
  const [view, setView] = React.useState<React.ReactNode>(null);

  const promise = React.useCallback(
    () =>
      new Promise<User>((resolve) => {
        if (viewer.user) {
          resolve(viewer.user);
          return;
        }

        const modalView = React.createElement(SignInModal, {
          onClose: (user) => {
            setView(null);
            resolve(user);
          },
        });

        setView(modalView);
      }),
    [viewer],
  );

  return [promise, view, viewer.user];
};
