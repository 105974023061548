import classNames from 'classnames';
import * as React from 'react';

export const Burger = ({ className }: { className?: string }) => (
  <svg
    width="25"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('stroke-current', className)}
  >
    <path d="M3.5 12h18m-18-8h18m-18 16h18" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
