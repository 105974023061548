import * as React from 'react';
import { navigate } from 'gatsby';
import { gql } from '@urql/core';
import cn from 'classnames';
import { useStoreon } from 'hooks';
import { captureException } from 'utils';
import { CreateAddress } from 'modals/CreateAddress';
import { PageError } from 'components/PageError';
import { Loader } from 'components/Loader';
import { AuthRequired } from 'components/AuthRequired';
import { Address } from 'components/Address';
import BasketIcon from 'components/icons/Basket';
import EditIcon from 'components/icons/Edit';

import {
  useChangeActiveAddressQuery,
  useBasicChangeActiveAddressDeleteMutation,
  useBasicChangeActiveAddressMutation,
  Address_Data,
} from 'generated/graphql';

type WrapperProps = {
  readonly className?: string;
  readonly hideTitle?: boolean;
};

const Wrapper: React.FC<WrapperProps> = ({ children, className, hideTitle }) => (
  <div
    style={{ minWidth: '50vw' }}
    className={cn('relative bg-white p-7 pb-10 rounded lg:w-full lg:min-h-full flex', className)}
  >
    <div className="m-auto w-full">
      {!hideTitle && <div className="text-center text-xl font-bold tracking-wide mb-5">Адреса доставки</div>}
      {children}
    </div>
  </div>
);

type Props = {
  readonly onClose: () => void;
  readonly className?: string;
  readonly navigateTo?: string;
  readonly createAddressClassName?: string;
  readonly hideTitle?: boolean;
};

export const ChangeActiveAddress = ({ onClose, className, navigateTo, createAddressClassName, hideTitle }: Props) => {
  const [queryRes, reexecuteQuery] = useChangeActiveAddressQuery();
  const [deleteMutationState, deleteMutate] = useBasicChangeActiveAddressDeleteMutation();
  const [updateMutationState, updateMutate] = useBasicChangeActiveAddressMutation();
  const { dispatch } = useStoreon();

  if (queryRes.error) {
    const onRetry = () => reexecuteQuery({ requestPolicy: 'network-only' });
    return (
      <Wrapper className={className} hideTitle={hideTitle}>
        <PageError error={queryRes.error} retry={onRetry} />
      </Wrapper>
    );
  }

  if (queryRes.fetching && !queryRes.data) {
    return (
      <Wrapper className={className} hideTitle={hideTitle}>
        <Loader className="flex justify-center" />
      </Wrapper>
    );
  }

  if (!queryRes.data) {
    captureException(new Error(`changeActiveAddress data is null`));
    return null;
  }

  const { user } = queryRes.data.viewer;

  if (!user) {
    return (
      <Wrapper className={className} hideTitle={hideTitle}>
        <AuthRequired />
      </Wrapper>
    );
  }

  if (user.addresses.length === 0) {
    return <CreateAddress onClose={onClose} className={createAddressClassName} hideTitle={hideTitle} />;
  }

  const handleDeleteAddress = (id: number) => () => {
    const deleteAddress = async () => {
      try {
        await deleteMutate({ id });
      } catch (error) {
        captureException(error);
        // eslint-disable-next-line
        alert(error.message);
      }
    };

    // eslint-disable-next-line
    if (confirm('Удалить адрес?')) {
      deleteAddress();
    }
  };

  const updateActiveAddressId = (id: number) => async () => {
    try {
      const res = await updateMutate({ id });

      if (res.__typename === 'UpdateActiveAddressIdPayload') {
        if (navigateTo) {
          navigate(`/${navigateTo}`);
        }

        onClose();
      }
    } catch (error) {
      captureException(error);
      // eslint-disable-next-line
      alert(error.message);
    }
  };

  const toCreateAddress = () => dispatch('setModal', { type: 'createAddress' });
  const toUpdateAddress = (prevAddressData: Address_Data) => {
    const { __typename: typename, ...prevAddress } = prevAddressData;
    dispatch('setModal', { type: 'createAddress', prevAddress, title: 'Редактировать адрес' });
  };

  return (
    <Wrapper className={className} hideTitle={hideTitle}>
      <div id="styled-scroll">
        {user.addresses.map((a) => {
          const active = a.id === user.activeAddress?.id;

          return (
            <div key={a.id} className="flex items-center p-2">
              <button type="button" onClick={updateActiveAddressId(a.id)} className="flex items-center flex-1">
                <div
                  className={cn('flex w-[30px] h-[30px] mr-2 rounded-full', active ? 'bg-orange-100' : 'bg-red-600')}
                >
                  {active && <div className="w-[10px] h-[10px] bg-orange-50 rounded-[10px] m-auto" />}
                </div>
                <div className="flex-1 text-left">
                  <Address data={a} className={cn(active ? 'font-medium pr-3' : '', 'line-clamp-1 max-w-[80vw]')} />
                  <div className="tracking-wide text-gray-50 opacity-70">{a.comment || 'Нет комментария'}</div>
                </div>
              </button>
              <button type="button" onClick={() => toUpdateAddress(a)} className="w-6 h-6 group mr-2">
                <EditIcon className="text-gray-50" style={{ color: 'blue' }} />
              </button>
              <button type="button" onClick={handleDeleteAddress(a.id)} className="w-6 h-6 group">
                <BasketIcon />
              </button>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        onClick={toCreateAddress}
        className="uppercase font-medium mt-3 px-5 py-2 rounded-full self-start bg-red-500"
      >
        Добавить
      </button>
      {(deleteMutationState.fetching || updateMutationState.fetching) && <Loader className="loader-overlay" />}
    </Wrapper>
  );
};

gql`
  fragment ChangeActiveAddress_user on User {
    id
    addresses {
      id
      ...Address_data
    }
    activeAddress {
      id
    }
  }

  query ChangeActiveAddressQuery {
    viewer {
      id
      user {
        ...ChangeActiveAddress_user
      }
    }
  }

  mutation ChangeActiveAddressDeleteMutation($input: DeleteAddressInput!) {
    result: deleteAddress(input: $input) {
      ... on DeleteAddressPayload {
        user {
          ...ChangeActiveAddress_user
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }

  mutation ChangeActiveAddressMutation($input: UpdateActiveAddressIdInput!) {
    result: updateActiveAddressId(input: $input) {
      ... on UpdateActiveAddressIdPayload {
        user {
          ...ChangeActiveAddress_user
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
