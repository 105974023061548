import * as React from 'react';
import { gql } from '@urql/core';
import { captureException } from 'utils';
import { DontUseAutoPayment_User, useBasicDontUseAutoPaymentMutation } from 'generated/graphql';
import { Switch } from './Switch';
import { Loader } from './Loader';

type Props = {
  readonly user: DontUseAutoPayment_User;
};

export const DontUseAutoPayment = ({ user }: Props) => {
  const [mutationState, mutate] = useBasicDontUseAutoPaymentMutation();

  const handleChange = async () => {
    try {
      await mutate({ dontUseAutoPayment: !user.dontUseAutoPayment });
    } catch (err) {
      captureException(err);
      // eslint-disable-next-line no-alert
      alert(err.message);
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div
      className="flex items-center py-5 lg:justify-between border-b relative"
      style={{ borderColor: 'hsla(0,0%,59.2%,.2)' }}
    >
      <div className="lg:order-2 flex items-center">
        <Switch value={user.dontUseAutoPayment} onChange={handleChange} />
      </div>
      <div className="ml-3 lg:order-1">
        Отключить автоплатеж
        <div className="text-sm">
          {user.dontUseAutoPayment ? (
            <>Мы пришлем вам SMS для оплаты после сборки заказа</>
          ) : (
            <>
              {user.lastBindedCard?.maskedPan ? (
                <>
                  После отправки заказа мы автоматически спишем полную сумму с Вашей карты{' '}
                  {user.lastBindedCard.maskedPan}.
                </>
              ) : (
                <>Мы спишем 1 рубль с Вашей карты, чтобы проверить её работоспособность</>
              )}
            </>
          )}
        </div>
      </div>
      {mutationState.fetching && <Loader className="loader-overlay" />}
    </div>
  );
};

gql`
  fragment DontUseAutoPayment_user on User {
    dontUseAutoPayment
    lastBindedCard {
      maskedPan
    }
  }

  mutation DontUseAutoPaymentMutation($input: ChangeProfileInput!) {
    result: changeProfile(input: $input) {
      ... on ChangeProfilePayload {
        user {
          id
          ...DontUseAutoPayment_user
        }
      }

      ... on ErrorPayload {
        message
      }
    }
  }
`;
