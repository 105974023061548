import * as React from 'react';
import cn from 'classnames';
import { BackButton } from './BackButton';

type Props = {
  title: string;
  backButton?: boolean;
  className?: string;
};

const Title = ({ title }: Pick<Props, 'title'>) => <h1 className="text-2xl font-bold lg:text-xl text-left">{title}</h1>;

const Wrapper: React.FC<Pick<Props, 'backButton' | 'className'>> = ({ children, backButton, className }) => {
  if (backButton) {
    return <BackButton className={cn(className, 'flex-shrink-0 my-10 lg:my-6')}>{children}</BackButton>;
  }
  return <div className={cn(className, 'flex my-10 lg:my-6 pl-2')}>{children}</div>;
};

export const ContentTitle = ({ title, backButton, className }: Props) => (
  <Wrapper className={className} backButton={backButton}>
    <Title title={title} />
  </Wrapper>
);
