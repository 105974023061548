import { ChatBody_Message, ChatMessageType } from 'generated/graphql';
import * as React from 'react';
import { format, parseISO } from 'date-fns';
import locale from 'date-fns/locale/ru';
import { gql } from 'urql';

import ManagerAvatar from './icons/managerAvatar.png';
import { SignInButton } from './SignInButton';
import { Loader } from './Loader';

type MessageProps = {
  body: string;
  createdAt?: string;
  images?: readonly { id: number; url: string }[];
};

const Images = ({ images }: { images: readonly { id: number; url: string }[] }) => (
  <div className="flex flex-wrap -m-1">
    {images.map((i) => (
      <a
        key={i.id}
        href={i.url}
        target="_blank"
        rel="noreferrer"
        className=" block h-[68px] w-[68px] bg-black rounded-md overflow-hidden relative m-1"
      >
        <img
          src={i.url}
          alt=""
          className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 min-h-full min-w-full"
        />
      </a>
    ))}
  </div>
);

const getMessageTime = (createdAt: string) => format(parseISO(createdAt), 'HH:mm, d MMMM', { locale });

const ClientMessage = ({ data }: { data: MessageProps }) => (
  <div className=" text-sm mb-4 ml-auto break-all">
    <div className="py-3.5 px-3.5 rounded-lg text-white bg-[#3047EC] rounded-br-sm whitespace-pre-wrap grid grid-cols-1 gap-2">
      {data.images?.length ? <Images images={data.images} /> : null}
      <div>{data.body}</div>
    </div>
    {data.createdAt && <div className="text-gray-300 p-1 text-xs">{getMessageTime(data.createdAt)}</div>}
  </div>
);

const ManagerMessage = ({ data }: { data: MessageProps }) => (
  <div className="flex items-end  mb-4">
    <div className="w-8 h-8 rounded-full overflow-hidden mr-2 flex-shrink-0 relative bottom-5">
      <img className="w-full h-full " src={ManagerAvatar} alt="manager" />
    </div>
    <div className="text-sm mr-auto break-all">
      <div className="py-3.5 px-3.5 rounded-lg bg-red-300 rounded-bl-sm whitespace-pre-wrap grid grid-cols-1 gap-2">
        {data.images?.length ? <Images images={data.images} /> : null}
        <div>{data.body}</div>
      </div>
      {data.createdAt && <div className="text-gray-300 p-1 text-xs">{getMessageTime(data.createdAt)}</div>}
    </div>
  </div>
);

const Messages = ({ messages }: { messages: readonly ChatBody_Message[] }) => {
  if (!messages.length)
    return (
      <ManagerMessage
        data={{
          body: 'Есть вопросы или пожелание? \nДавайте обсудим',
        }}
      />
    );

  return (
    <>
      {messages.map((message) => {
        if (message.type === ChatMessageType.FromClient) {
          return <ClientMessage key={message.id} data={message} />;
        }
        return <ManagerMessage key={message.id} data={message} />;
      })}
    </>
  );
};

const ErrorView = ({ errorMessage }: { errorMessage: string }) => (
  <div className="text-center">
    <div>{errorMessage}</div>
  </div>
);

const AuthView = () => (
  <>
    <ManagerMessage
      data={{
        body: 'Есть вопросы или пожелание? \nДавайте обсудим',
      }}
    />
    <div>
      <div className="text-sm text-center mb-3 text-gray-50 leading-5">
        Пожалуйста, войдите в аккаунт, чтобы общаться с менеджером поддержки
      </div>
      <SignInButton classNames="px-10 py-2 bg-[#3047ec] text-white mx-auto block rounded-3xl">Войти</SignInButton>
    </div>
  </>
);

type Props = {
  messages: readonly ChatBody_Message[];
  error?: Error | null;
  token: string | null;
  loading: boolean;
};

export const ChatBody = ({ messages, error, token, loading }: Props) => {
  if (loading) {
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );
  }

  if (!token) {
    return (
      <div className="flex flex-col justify-between h-full py-4 px-4 overflow-y-auto">
        <AuthView />
      </div>
    );
  }

  return (
    <div className="flex flex-col-reverse py-4 px-4 overflow-y-auto">
      {error ? <ErrorView errorMessage={error.message} /> : <Messages messages={messages} />}
    </div>
  );
};

gql`
  fragment ChatBody_message on ChatMessage {
    id
    createdAt
    type
    body
    images {
      id
      url
    }
  }
`;
