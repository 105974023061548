import classNames from 'classnames';
import * as React from 'react';

export const User = ({ className }: { className?: string }) => (
  // <svg
  //   width="24"
  //   height="24"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   className={classNames('fill-current', className)}
  // >
  //   <path d="m13.47 4.8.496.496-.495-.495zm7.48 7.49-.494-.495a.75.75 0 0 0-.02.02l.515.474zM12 22l-.515.474a.7.7 0 0 0 1.03 0L12 22zM10.53 4.8l-.496.496.495-.495zm-7.48 0 .494.496L3.05 4.8zm0 7.49.514-.475a.655.655 0 0 0-.02-.02l-.495.494zM12 6.272l-.495.495a.7.7 0 0 0 .99 0L12 6.273zm1.966-.977a4.585 4.585 0 0 1 6.49 0l.99-.99a5.985 5.985 0 0 0-8.47 0l.99.99zm6.49 0a4.6 4.6 0 0 1 0 6.498l.99.99a6 6 0 0 0 0-8.478l-.99.99zm-.02 6.519-8.95 9.71 1.029.95 8.95-9.711-1.029-.95zM2.554 4.306a6 6 0 0 0 0 8.478l.99-.99a4.6 4.6 0 0 1 0-6.498l-.99-.99zm-.02 8.458 8.951 9.71 1.03-.948-8.951-9.711-1.03.949zm10.442-8.458L11.505 5.78l.99.99 1.471-1.473-.99-.99zm-1.952 0a5.985 5.985 0 0 0-8.47 0l.99.99a4.585 4.585 0 0 1 6.49 0l.99-.99zm1.471 1.473-1.47-1.473-.991.99 1.47 1.472.991-.99z" />
  // </svg>
  <svg
    width="24"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('fill-current stroke-current', className)}
  >
    <circle
      cx="12"
      cy="6.75"
      r="4.5"
      style={{ fill: 'white', stroke: 'inherit' }}
      strokeWidth="1.4"
      className="!fill-white"
    />
    <path
      style={{ strokeWidth: 0 }}
      d="m20.993 18.216-.698.06.698-.06zm-17.986 0 .698.06-.698-.06zm17.21 1.644.37.595-.37-.595zm-16.434 0 .37-.595-.37.595zm16.479-4.2.637-.29-.637.29zm-.414 3.605c-1.078.67-3.518 1.785-7.848 1.785v1.4c4.585 0 7.282-1.185 8.587-1.995l-.74-1.19zM12 21.05c-4.33 0-6.77-1.115-7.848-1.785l-.739 1.19c1.305.81 4.002 1.995 8.587 1.995v-1.4zm-9.69-2.894c-.076.886.292 1.795 1.103 2.299l.74-1.19c-.304-.188-.486-.554-.448-.99l-1.395-.12zm17.985.12c.038.435-.144.801-.447.99l.739 1.189c.811-.504 1.18-1.413 1.103-2.3l-1.395.12zm-.67-2.327c.31.686.601 1.52.67 2.327l1.395-.12c-.088-1.03-.448-2.03-.79-2.785l-1.276.578zm-15.92 2.327c.069-.807.36-1.641.67-2.327l-1.274-.579c-.343.756-.703 1.755-.791 2.786l1.395.12zm14.19-4.726H6.106v1.4h11.79v-1.4zm-13.52 2.399c.279-.614.943-.999 1.73-.999v-1.4c-1.22 0-2.455.608-3.004 1.82l1.275.579zM20.9 15.37c-.55-1.212-1.785-1.82-3.003-1.82v1.4c.786 0 1.45.385 1.728.999l1.275-.579z"
    />
  </svg>
);
