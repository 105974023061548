import * as React from 'react';
import { gql } from '@urql/core';
import { useForm, Controller } from 'react-hook-form';
import { useStoreon } from 'hooks';
import { captureException } from 'utils';
import { Rating } from 'components/Rating';
import { ProgressButton } from 'components/ProgressButton';
import { PageError } from 'components/PageError';
import { useBasicChangeProductReviewMutation, useChangeProductReviewQuery } from 'generated/graphql';

type Props = {
  id: number | undefined;
  onClose: () => void;
  rating: number;
  body?: string;
};

type ChangeProductReviewState = {
  rating: number;
  text: string;
};

// all texts must be transparent, they are used for correct width/height
const ChangeProductReviewSkeleton = () => (
  <div className="bg-white p-10 flex flex-col items-center justify-center min-h-full rounded skeleton-loader-parent">
    <div className="skeleton-loader-overlay" />
    <div className="mb-3 font-bold text-xl text-transparent bg-gray-400">Ваша оценка: 5.0</div>
    <Rating rating={0} className="mb-4" />
    <div className="bg-gray-400 text-sm text-black placeholder-black resize-none w-96 h-24 rounded-md px-4 py-2" />
    <div
      style={{ color: 'transparent' }}
      className="btn bg-gray-400 mx-auto items-center justify-center flex-col overflow-hidden mt-5 mb-6"
    >
      Отправить
    </div>
  </div>
);

export const ChangeProductReview = ({ id, onClose, rating, body }: Props) => {
  const { user } = useStoreon('user');
  const [res, refetchQuery] = useChangeProductReviewQuery({ variables: { id: id || 0 }, pause: !id });
  const [mutationState, mutate] = useBasicChangeProductReviewMutation();
  const { control, handleSubmit } = useForm<ChangeProductReviewState>({
    defaultValues: {
      rating,
      text: body,
    },
  });
  // React.useEffect(() => {
  //   if (res.data && res.data.product) {
  //     const oldReview = res.data.product.reviews.find((review) => review.myOwn);
  //     if (oldReview && oldReview.body) {
  //       setValue('text', oldReview.body);
  //     }
  //   }
  // }, [res.data]);

  React.useEffect(() => {
    if (!id || !user) {
      onClose();
    }
  }, [onClose, id, user]);

  const rootClassNames = 'bg-white p-10 flex flex-col items-center justify-center min-h-full rounded';

  if (res.fetching || !res.data || mutationState.fetching) {
    return <ChangeProductReviewSkeleton />;
  }

  if (res.error) {
    const retry = () => refetchQuery({ requestPolicy: 'network-only' });
    return (
      <div className={rootClassNames}>
        <PageError error={res.error} retry={retry} />
      </div>
    );
  }

  if (!res.data.product) {
    return <div className={rootClassNames}>Неверный продукт</div>;
  }
  const onSubmit = handleSubmit(async (data) => {
    try {
      const response = await mutate({
        productId: id!,
        rating: data.rating,
        body: data.text || undefined,
      });

      if (response.__typename === 'CreateProductReviewPayload') {
        onClose();
      }
    } catch (err) {
      captureException(err);
    }
  });

  return (
    <form className={rootClassNames} onSubmit={onSubmit}>
      <Controller
        control={control}
        name="rating"
        render={({ field: { value, onChange } }) => (
          <>
            <div className="mb-3 font-bold text-xl">{value ? `Ваша оценка: ${value}.0` : 'Оцените товар'}</div>
            <Rating rating={value} className="mb-4" onClick={(val: number) => onChange(val)} />
          </>
        )}
      />
      <Controller
        control={control}
        name="text"
        render={({ field: { value, onChange } }) => (
          <textarea
            className="bg-gray-400 text-sm text-black placeholder-black resize-none w-96 h-24 rounded-md px-4 py-2"
            placeholder="Комментарий (по желанию)"
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            onChange={(e) => onChange(e.target.value)}
            defaultValue={value}
          />
        )}
      />

      <ProgressButton
        type="submit"
        isProgress={mutationState.fetching}
        styles="btn btn-orange mt-5 mx-auto items-center justify-center flex-col overflow-hidden mt-7 mb-6"
      >
        Отправить
      </ProgressButton>
    </form>
  );
};

export const query = gql`
  query ChangeProductReviewQuery($id: Int!) {
    product(id: $id) {
      id
      reviews {
        productId
        body
      }
    }
  }

  mutation ChangeProductReviewMutation($input: CreateProductReviewInput!) {
    result: createOrUpdateProductReview(input: $input) {
      ... on CreateProductReviewPayload {
        user {
          id
          productReviews {
            id
            productId
            rating
            body
            createdAt
          }
        }
      }
      ... on ErrorPayload {
        message
      }
    }
  }
`;
