import * as React from 'react';
import { navigate } from 'gatsby';
import cn from 'classnames';
import Icon from './icons/ArrowBack';

type Props = {
  readonly className?: string;
};

export const BackButton: React.FC<Props> = ({ children, className }) => {
  const goBack = () => navigate(-1);

  return (
    <button
      type="button"
      onClick={goBack}
      className={cn('flex items-center transition-opacity duration-300 ease-in-out hover:opacity-70', className)}
    >
      <Icon size={30} className="mr-3 lg:mr-2 flex-shrink-0" />
      {children}
    </button>
  );
};
