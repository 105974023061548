import * as React from 'react';
import OkImage from 'images/okRounded.svg';
import modalOverlay from 'images/okModalOverlay.svg';

type Props = {
  onClose: () => void;
  promoCode: string;
};

export const PromoCodeActivated = ({ onClose, promoCode }: Props) => (
  <div className="bg-white w-[350px] rounded-[10px] flex relative py-[35px] lg:w-full lg:min-h-full lg:rounded-none justify-center px-3">
    <div className="flex flex-col items-center m-auto">
      <img src={OkImage} style={{ maxWidth: 100 }} alt="ok" className="pb-5" />
      <div className="pb-5 text-[25px] text-center leading-7">Промокод {promoCode} активирован</div>
      <img className="absolute left-0 bottom-0" src={modalOverlay} alt="overlay" />
      <button style={{ height: '48px' }} type="button" onClick={onClose} className="btn btn-orange">
        ЗАКРЫТЬ
      </button>
    </div>
  </div>
);
