import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { ProductBadge_Data } from 'generated/graphql';

type Props = {
  readonly data: ProductBadge_Data;
  readonly currentCategoryId?: number;
};

export const ProductBadge = ({ data, currentCategoryId }: Props) => {
  if (data.badgeCategories.length === 0) return null;
  return (
    <div className="absolute left-4 top-0 z-0 xs:left-2">
      {data.badgeCategories.map((c) => {
        if (currentCategoryId && currentCategoryId === c.id) return null;

        return (
          <Link
            key={c.id}
            to={c.pageUrl}
            style={{ color: c.primaryColor }}
            className="relative flex items-center justify-center text-[14px] font-semibold tracking-[1px] py-2 px-4 last:rounded-bl last:rounded-br overflow-hidden xs:px-2 xs:py-1"
          >
            <div
              style={{
                backgroundColor: c.extraColor,
              }}
              className="absolute w-full h-full z-[-1]"
            />
            {c.name}
          </Link>
        );
      })}
    </div>
  );
};

export const fragment = graphql`
  fragment ProductBadge_data on Product {
    badgeCategories {
      id
      name
      pageUrl
      primaryColor
      extraColor
    }
  }
`;
