import * as React from 'react';
import cn from 'classnames';
import { formatMoney, NBSP } from 'utils';

type Props = {
  readonly value: number;
  readonly allowFree?: boolean;
  readonly className?: string;
};

const renderLabel = (value: number, allowFree?: boolean) => {
  if (value !== 0) {
    return `${formatMoney(value)}${NBSP}₽`;
  }

  if (value === 0 && allowFree) {
    return 'БЕСПЛАТНО';
  }

  return `0${NBSP}₽`;
};

export const Price = ({ value, allowFree, className }: Props) => (
  <div className={cn('font-semibold whitespace-nowrap', value === 0 && allowFree && 'text-orange-50', className)}>
    {renderLabel(value, allowFree)}
  </div>
);
