import { AttachedImage } from 'hooks';
import * as React from 'react';

type Props = {
  images: AttachedImage[];
  onRemove: (id: number) => void;
};

export const AttachedImages = ({ images, onRemove }: Props) => (
  <div className="flex px-3 pt-2 pb-2">
    {images.map((i) => (
      <div key={i.id} className="relative mr-2">
        <a className="overflow-hidden w-10 h-10" href={i.url} target="__blank">
          <img
            className="w-10 h-10 rounded-lg hover:scale-[1.2] hover:transform transition-all duration-300 lg:hover:scale-0"
            src={i.url}
            alt=""
          />
        </a>
        <button
          type="button"
          onClick={() => onRemove(i.id)}
          className="text-sm absolute w-5 h-5 bg-red-50 text-white rounded-full -top-1.5 -right-1.5 flex items-center justify-center leading-none rotate-0 hover:transform hover:rotate-90 lg:hover:rotate-0 transition-transform duration-300"
        >
          <span className="font-bold text-xs ">&#10005;</span>
        </button>
      </div>
    ))}
  </div>
);
