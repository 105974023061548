import * as React from 'react';
import { Link } from 'gatsby';
import { Transition, TransitionStatus } from 'react-transition-group';

type BaseItem = {
  label: string;
  icon?: JSX.Element | null;
  badge?: number;
  warningDot?: boolean;
};

type ActionItem = BaseItem & {
  onClick: () => void;
};

type LinkItem = BaseItem & {
  to: string;
};

type Item = LinkItem | ActionItem;

type Props = {
  readonly children: (opened: boolean) => React.ReactNode;
  readonly className: string;
  readonly items: Readonly<Array<Item | null>>;
  readonly ariaPrefix: string;
};

const transitionStyles: { [key in TransitionStatus]: string } = {
  entering: 'transform opacity-0 scale-95',
  entered: 'transform opacity-100 scale-100 pointer-events-auto',
  exiting: 'transform opacity-100 scale-100',
  exited: 'transform opacity-0 scale-95 pointer-events-none',
  unmounted: '',
};

const isLink = (item: Item): item is LinkItem => Object.prototype.hasOwnProperty.call(item, 'to');

const linkCn = 'block text-black text-sm mb-4 transition-colors duration-300 ease-in-out hover:text-orange-200';

const menuCn =
  'origin-top-right absolute p-5 right-0 mt-4 w-56 rounded-md shadow-lg bg-white border border-red-200 focus:outline-none duration-200 transition';

export const DropDown = ({ children, items, className, ariaPrefix }: Props) => {
  const [opened, setOpened] = React.useState(false);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const nodeRef = React.useRef(null);

  const toggle = () => {
    setOpened((v) => !v);
  };

  React.useEffect(() => {
    const fn = (e: MouseEvent) => {
      if (!rootRef.current?.contains(e.target as Node)) {
        setOpened(false);
      }
    };

    document.addEventListener('click', fn);

    return () => document.removeEventListener('click', fn);
  }, []);

  return (
    <div className="relative inline-block text-left z-0" ref={rootRef}>
      <button
        type="button"
        id={`options-menu-${ariaPrefix}`}
        aria-expanded="true"
        aria-haspopup="true"
        onClick={toggle}
        className={className}
      >
        {children(opened)}
      </button>
      <Transition nodeRef={nodeRef} in={opened} timeout={0}>
        {(state) => (
          <div
            ref={nodeRef}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby={`options-menu-${ariaPrefix}`}
            className={`${menuCn} ${transitionStyles[state]}`}
          >
            {items.map((item) => {
              if (item === null) return null;
              if (isLink(item)) {
                return (
                  <Link
                    key={item.label}
                    to={item.to}
                    className={`flex justify-between ${linkCn}`}
                    activeClassName="text-orange-200"
                  >
                    <span className="relative">
                      {item.label}
                      {item.warningDot && (
                        <span className="absolute -top-0.5 -right-2.5 h-2 w-2 bg-orange-50 rounded-full" />
                      )}
                    </span>
                    {item.icon}
                    {item.badge && (
                      <span
                        className="text-xs text-gray-50 bg-red-200 px-1.5 rounded-full flex justify-center items-center"
                        style={{ height: '20px' }}
                      >
                        <span className="w-full">{item.badge}</span>
                      </span>
                    )}
                  </Link>
                );
              }
              return (
                <div
                  key={item.label}
                  className={`border-t border-gray-600 pt-4 text-gray-200 lg:border-t-0 lg:text-left ${linkCn}`}
                  style={{ marginBottom: '0' }}
                >
                  <button type="submit" onClick={item.onClick} className="w-full text-left">
                    {item.label}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </Transition>
    </div>
  );
};
